import React, { useState, useEffect } from 'react';
import {
    Spin,
    Layout,
    Space,
    Button,
    Typography,
    DatePicker,
    Form,
    Select,
    Checkbox,
    message
} from 'antd';
import { GET_ITEM_NAMES_REDUCED, GET_CAMPAIGNS } from '../../queries';
import { UPDATE_CAMPAIGN_PLAN } from '../../mutations';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { isSearchMatch } from '../../Utilities/isSearchMatch';
import { localTimeZoneToUTC } from '../../Utilities/date';

const EditCampaignPlan = ({ setEditingPlan, campaignPlan, refetch }) => {
    const { data, loading } = useQuery(GET_ITEM_NAMES_REDUCED, { fetchPolicy: 'network-only', variables: { search: `department = ${JSON.parse(localStorage.currentUser)?.department}` } });
    const { data: campaignData, loading: campaignLoading } = useQuery(GET_CAMPAIGNS);
    const [campaigns, setCampaigns] = useState([])
    const [itemsError, setItemsError] = useState(false)
    const [updateCampaignPlan] = useMutation(UPDATE_CAMPAIGN_PLAN, {
        onCompleted: (data) => {
            message.success('キャンペーン計画を変更しました。');
            form.resetFields();
            refetch();
            setEditingPlan(false);
        },
        onError: (error) => {
            console.log(error);
        }
    });
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([])
    const [all, setAll] = useState(false)
    const [form] = Form.useForm();
    const formCampaignLayout = {
        labelCol: { offset: 0, span: 3 },
        wrapperCol: {
            xs: { span: 20 },
            sm: { span: 8 },
        },
        layout: 'horizontal',
        labelAlign: 'right'
    };

    const onSubmit = (values) => {
        const variables = {...values}
        if (all) {
            variables.items = filteredProducts.map(e => {return e.node.id})
        }
        if (variables.items.length < 1) {
            setItemsError(true)
            return
        }
        setItemsError(false)
        updateCampaignPlan({ variables: { input: {
            id: parseInt(campaignPlan.id),
            campaignId: values.campaign,
            startDt: localTimeZoneToUTC(values.campaignRange[0]),
            endDate: localTimeZoneToUTC(values.campaignRange[1]),
            itemIdList: variables.items 
        }}});
    }

    const filterProducts = (range) => {
        setFilteredProducts(data.itemNames?.edges) // reset
        if(!range) {
            return
        }
        const start = range[0].format().slice(0,10) // gave up on using moment's comparisons and conversions, vanilla js works better somehow
        const end = range[1].format().slice(0,10)

        const filtered = products.filter(e => {
            const cycles = e.node.itemSellingCycles
            const status = e.node.userDefinedStatus ? e.node.userDefinedStatus : e.node.status
            if (status === 'Regular') {
                return true
            }
            if (cycles.length > 0) {
                let sold = false
                cycles.forEach(cycle => {
                    if (cycle.sellingStartDt.slice(0,10) >= start && cycle.sellingEndDt.slice(0,10) <= end) {
                        sold = true
                    }
                })
                return sold
            }
            return false
        })
        setFilteredProducts(filtered)
    }

    const onSelect = e => {
        setAll(e.target.checked)
    }

    useEffect(() => {
        if (data) {
            setProducts(data.itemNames?.edges);
            setFilteredProducts(data.itemNames?.edges)
        }
    }, [data]);

    useEffect(() => {
        if(campaignData) {
            setCampaigns(campaignData?.campaigns)
        }
    }, [campaignData]);

    if (loading || campaignLoading) {
        return <Spin/>;
    }

    return <>
        <Layout style={{ padding: 0, background: '#fff' }}>
            <Typography.Title level={4}>キャンペーン計画内容</Typography.Title>
        </Layout>
        <Layout style={{ padding: 24, background: '#fff' }}>
            <Form
                form={form}
                {...formCampaignLayout}
                onFinish={onSubmit}
                initialValues={{
                    campaign: campaignPlan.campaignId.toString(),
                    campaignRange: [moment(campaignPlan.start), moment(campaignPlan.end)],
                    items: campaignPlan.items.map(item => item.id.toString())
                }}
            >
                <Form.Item label='キャンペーン名' name='campaign' required>
                    <Select 
                        placeholder="キャンペーン名" 
                        showSearch
                    >
                        {campaigns.map(campaign => (
                            <Select.Option key={campaign.id}>{campaign.campaignName}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label='キャンペーン期間' name='campaignRange' required>
                    <DatePicker.RangePicker onChange={filterProducts}/>
                </Form.Item>
                <Form.Item label='商品名' name='items' validateStatus={itemsError ? 'error' : null} required>
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder="商品を入力してください"
                        disabled={all}
                        filterOption={(input, option) => {
                            const searchString = input.toLowerCase();
                            const targetString = option.children[0].toLowerCase();
                            return isSearchMatch(searchString, targetString);
                        }}
                    >
                        {filteredProducts.map(product => (
                            <Select.Option key={product.node.id}>{product.node.itemName}{product.node.itemSize ? " - " + product.node.itemSize : null}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name='allProductsSelected' valuePropName="checked" style={{marginLeft: '9rem'}}>
                    <Checkbox onChange={onSelect}>全ての商品を選択する</Checkbox>
                </Form.Item>
                <Form.Item colon={false} label=' '>
                    <Space>
                        <Button type='primary' htmlType='submit'>変更する</Button>
                        <Button onClick={() => setEditingPlan(false)}>キャンセル</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Layout>
    </>
};

export default EditCampaignPlan;
