import React, { useState, useEffect } from 'react';
import { Spin, Layout, Space, Typography, Modal, message } from 'antd';
import { GET_FORWARDING_OFF_PLANS } from '../../../queries';
import { CREATE_FORWARDING_OFF_PLANS } from '../../../mutations';
import { useQuery, useMutation } from '@apollo/client';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ja'; 
import { localTimeZoneDate } from '../CampaignPlans/CampaignSchedule';
import DeleteForwardingOffPlansButton from './DeleteForwardingOffPlansButton';
import CalendarDatePicker from '../CalendarDatePicker';
import { getFormattedDate } from '../../../Utilities/date';
moment.locale('ja', {
    week: {
        dow: 1,
        doy: 1
    }
});
const localizer = momentLocalizer(moment);

const ForwardingOffSchedule = () => {
    const [addingPlan, setAddingPlan] = useState(false);
    const [offDays, setOffDays] = useState([]);
    const [selectedOffDay, setSelectedOffDay] = useState(null);
    const [offDaysShow, setOffDaysShow] = useState(false);
    const [selectedDate, setSelectedDate] = useState(
        localStorage.getItem('forwardingOffPlanCalendarDate') ? new Date(localStorage.getItem('forwardingOffPlanCalendarDate')) : new Date()
        );
    const { data, loading, refetch } = useQuery(GET_FORWARDING_OFF_PLANS);
    const [createForwardingOffPlans] = useMutation(CREATE_FORWARDING_OFF_PLANS, {
        onCompleted: (data) => {
            message.success('廻送計画を追加しました。');
            refetch();
            setAddingPlan(false);
        },
        onError: (error) => {
            console.log(error);
            message.error('廻送計画追加に失敗しました。');
            
        }
    });

    const showSelectedOffDay = () => {
        setOffDaysShow(true);
    };
    
    const hideModal = () => {
        setOffDaysShow(false);
    };
    const eventStyleGetter = () => {
        const style = {
          backgroundColor: '#E4EFDC',
          borderRadius: '2px',
          opacity: 0.8,
          color: 'black',
          border: '0px',
          height: '8rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '2rem'
        };
        return {
          style: style
        };
    };
    const modalHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography.Text strong>廻送なし</Typography.Text>
            <Space style={{ marginRight: 30 }}>
                <DeleteForwardingOffPlansButton 
                    selectedOffDay={selectedOffDay}
                    handleCompleted={ () => { hideModal(); refetch(); }}
                />
            </Space>
        </div>
    );

    const sendNewPlan = () => {
        createForwardingOffPlans({
            variables: { input: { offDays: moment(selectedDate).format('YYYY-MM-DD') } }
        });
    };

    useEffect(() => {
        if (data) {
            const plans = data.forwardingOffPlans.map((plan, idx) => {
                const start = localTimeZoneDate(plan);
                const formattedRange = getFormattedDate(start);
                return {
                    id: idx,
                    allDay: true,
                    title: '廻送なし',
                    start,
                    end: start,
                    formattedRange
                }
            })
            setOffDays(plans)
        }
    }, [data]);

    return (
    <Layout style={{ padding: 24, background: '#fff' }}>
        {loading ? 
            <Spin /> : 
            <Space direction='vertical' style={{ textAlign: 'center' }}>
                <Calendar
                    localizer={localizer}
                    views={['month']}
                    events={offDays}
                    selectable
                    date={selectedDate}
                    endAccessor={(event) => {
                        const endDate = moment(event.end)
                        return endDate.add(1, 'days')
                    }}
                    defaultView='month'
                    style={{ height: 1100 }}
                    onSelectEvent={event => { setSelectedOffDay(event); showSelectedOffDay(); }}
                    onSelectSlot={(slotInfo) => {
                        setAddingPlan(true);
                        setSelectedDate(slotInfo.start)
                    }}
                    eventPropGetter={eventStyleGetter}
                    components={{
                        toolbar: ({onNavigate}) => <CalendarDatePicker
                                                        onNavigate={onNavigate}
                                                        setAddingPlan={setAddingPlan}
                                                        selectedDate={selectedDate}
                                                        setSelectedDate={setSelectedDate}
                                                        isCampaignSchedule={false}
                                                        isFowardingOffSchedule={true}
                                                    />
                                    }}
                    >
                </Calendar>
            </Space>}

        {selectedOffDay &&
            <Modal 
                open={offDaysShow}
                onCancel={hideModal}
                title={modalHeader}
                footer={null}
            >
                <p>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography.Text strong style={{ width: 140 }}>日付:</Typography.Text>
                        <Typography.Text>{selectedOffDay.formattedRange}</Typography.Text>
                    </div>
                </p>
            </Modal>
        }
        {
            addingPlan &&
            <Modal
                open={addingPlan}
                onCancel={() => setAddingPlan(false)}
                title='廻送計画設定'
                onOk={sendNewPlan}
                okText='追加'
            >
                <p>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography.Text strong style={{ width: 140 }}>廻送なし:</Typography.Text>
                        <Typography.Text>{moment(selectedDate).format('YYYY年MM月DD日')}</Typography.Text>
                    </div>
                </p>
            </Modal>
        }
    </Layout>
            
    )
}

export default ForwardingOffSchedule;