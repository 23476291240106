import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Typography, Form, Input, Row, Space, Select } from 'antd';
import { CREATE_USER } from '../../mutations';
import propTypes from 'prop-types';

const AddUserModal = ({ handleCompleted, returnToTable  }) => {
    const [createUser] = useMutation(CREATE_USER, {
        onCompleted: (data) => {
            handleCompleted(data.createUser.user);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const onFinish = ({ email, firstName, lastName, department }) => {
        createUser({ variables: { input: { email, firstName, lastName, department } } });
    };

    const [form] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({});
    }, []);

    return <Form
        labelCol={ { span: 4 } }
        wrapperCol={ { span: 8 } }
        name='newuser'
        form={form}
        layout='vertical'
        onFinish={onFinish}
    >
        <Form.Item>
            <Typography.Title level={5}>新規追加</Typography.Title>
        </Form.Item>
        <Form.Item
            label="メール"
            name='email'
            rules={[{ required: true, message: 'メールアドレスを入力してください。' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="姓"
            name='lastName'
            rules={[{ required: true, message: '姓を入力してください。' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="名"
            name='firstName'
            rules={[{ required: true, message: '名を入力してください。' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="事業部"
            name="department"
            rules={[{ required: true, message: '事業部を入力してください。' }]}
        >
            <Select
                placeholder="事業部を選択してください。"
            >
                <Select.Option value="sweets">洋菓子事業部</Select.Option>
                <Select.Option value="snacks">菓子事業部</Select.Option>
            </Select>
        </Form.Item>

        <Row> 
            <Space>
       
        <Form.Item shouldUpdate>
            { () => (
                <Button
                    disabled={ !form.isFieldTouched('email') ||
                        !form.isFieldTouched('lastName') ||
                        !form.isFieldTouched('firstName') ||
                        !form.isFieldTouched('department') ||
                        form.getFieldsError().filter(({ errors }) => errors.length).length}
                    type="primary"
                    htmlType='submit'
                >
                    追加する
                </Button>
            )}
        </Form.Item>

        <Form.Item>
                    <Button onClick={returnToTable}>
                        キャンセル
                    </Button>
                </Form.Item>
        </Space>
        </Row>
    </Form>;
};

AddUserModal.propTypes = {
    handleCompleted: propTypes.func
};

export default AddUserModal;
