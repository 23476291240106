import React from 'react';
import {
    Layout,
    Tabs
} from 'antd';
import {
    CalendarOutlined,
    RocketOutlined,
    UnorderedListOutlined,
    TagsOutlined
} from '@ant-design/icons';
import ItemList from '../ItemList/ItemList';
import Campaigns from '../Campaigns/Campaigns';
import CalendarTab from '../Calendars/CalendarTab';
import AddTags from './AddTags';

const AddMenu = () => {
    const [shouldRefetch, setShouldRefetch] = React.useState(false);
    return <Layout>
        <Tabs tabBarStyle={{ paddingLeft: '24px', background: '#fff' }} defaultActiveKey='all'>
            <Tabs.TabPane tab={<span><UnorderedListOutlined/>商品一覧</span>} style={{ paddingLeft: 24 }} key='itemList'>
                <ItemList shouldRefetch={shouldRefetch} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span><RocketOutlined/>キャンペーン一覧</span>} style={{ paddingLeft: 24 }} key='campaigns'>
                <Campaigns />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span><CalendarOutlined/>カレンダー</span>} style={{ paddingLeft: 24 }} key='campaignPlan'>
                <CalendarTab />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span><TagsOutlined />タグ</span>} style={{ paddingLeft: 24 }} key='tags'>
                <AddTags setShouldRefetch={setShouldRefetch}/>
            </Tabs.TabPane>
        </Tabs>
    </Layout>
};

export default AddMenu;
