import React, { useState, useMemo, useEffect } from "react";
import { Layout, Form, Input, Select, Button, Tag, Typography, Divider, Modal, message } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { GET_USER_TAGS } from "../../queries";
import { CREATE_TAG, DELETE_TAG } from "../../mutations";

const colorOptions = [
	{ value: "magenta", label: "magenta" },
	{ value: "red", label: "red" },
	{ value: "orange", label: "orange" },
	{ value: "gold", label: "gold" },
	{ value: "lime", label: "lime" },
	{ value: "green", label: "green" },
	{ value: "cyan", label: "cyan" },
	{ value: "blue", label: "blue" },
];

const AddTags = ({ setShouldRefetch }) => {
	const [form] = Form.useForm();
	const [tags, setTags] = useState([]);
	const [deleteTagId, setDeleteTagId] = useState(false);
	const [deleteTagModal, setDeleteTagModal] = useState(false);

	const currentUser = useMemo(() => {
		return JSON.parse(localStorage.getItem("currentUser"));
	}, []);

	const { data, refetch } = useQuery(GET_USER_TAGS, {
		fetchPolicy: "no-cache",
		variables: { userId: currentUser.id },
	});

	const refetchTags = () => {
		setShouldRefetch("tags");
		refetch();
		localStorage.setItem("refetchTags", true);
	};

	useEffect(() => {
		if (data) {
			setTags(data.tags);
		}
	}, [data]);

	const [addTag] = useMutation(CREATE_TAG, {
		onCompleted: () => {
			refetchTags();
			form.resetFields();
		},
		onError: (error) => {
			console.log(error);
		},
	});

	const [deleteTag] = useMutation(DELETE_TAG, {
		onCompleted: () => {
			refetchTags();
		},
		onError: (error) => {
			console.log(error);
		},
	});

	const onSubmit = (values) => {
		if (tags.map(t => t.name).includes(values.name)) {
			message.error("既に使用されている名前です。");
			return
		}
		addTag({
			variables: {
				input: {
					userId: currentUser.id,
					name: values.name,
					color: values.color,
				},
			},
		});
	};

	return (
		<Layout style={{ padding: 24, background: "#fff" }}>
			<Typography.Title level={4}>タグ管理</Typography.Title>
			<Form form={form} style={{ marginTop: "2rem", maxWidth: "50rem" }} onFinish={onSubmit} autoComplete="off" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
				<Form.Item label="現在あるタグ">
					{tags.map((t) => {
						return (
							<Tag
								color={t.color}
								style={{
									marginTop: 3,
									marginRight: 3,
								}}
								key={t.id}
								closable
								onClose={(e) => {
									e.preventDefault();
									setDeleteTagModal(true);
									setDeleteTagId(t.id);
								}}>
								{t.name}
							</Tag>
						);
					})}
				</Form.Item>
				<Divider />
				<Form.Item name="name" label="新しいタグの名前">
					<Input />
				</Form.Item>
				<Form.Item name="color" label="新しいタグの色">
					<Select showArrow>
						{colorOptions.map((c) => {
							return (
								<Select.Option value={c.value} key={c.value}>
									<Tag
										color={c.value}
										style={{
											marginRight: 3,
										}}>
										{c.label}
									</Tag>
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item wrapperCol={{ offset: 4}}>
					<Button type="primary" htmlType="submit">
						タグを作成する
					</Button>
				</Form.Item>
			</Form>
			<Modal
				open={deleteTagModal}
				onCancel={() => {
					setDeleteTagModal(false);
				}}
				onOk={() => {
					deleteTag({
						variables: {
							input: {
								id: deleteTagId,
							},
						},
					});
					setDeleteTagModal(false);
				}}>
				本当に消していいですか？
			</Modal>
		</Layout>
	);
};

export default AddTags;
