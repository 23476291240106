import React, { useState, useEffect } from 'react';
import { Alert, Button, Typography, Row, Col, Space, Input, Table, Skeleton, Select, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USERS } from '../../queries';
import { UPDATE_USER } from '../../mutations';
import { authenticationService } from '../../Utilities/authenticationService';
import history from '../../Utilities/history';
import AddUserModal from './AddUserModal';
import NewUserModal from './NewUserModal';
import DeleteUsersButton from './DeleteUsersButton';
import PasswordResetButton from './PasswordResetButton';
import EmailEditButton from './EmailEditButton';

const UserManagement = ({ apolloClient }) => {
    const [currentModal, setCurrentModal] = useState('table');
    const [newUserCreated, setNewUserCreated] = useState(false);
    const [usersDeleted, setUsersDeleted] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const { data, error, loading, refetch } = useQuery(GET_USERS);
    const [rows, setRows] = useState([]);

    const returnToTable = () => {
        setCurrentModal('table');
    };

    const [updateUser] = useMutation(UPDATE_USER, {
        onCompleted: (data) => {
            message.success('ユーザー情報を更新しました。');
            const user = data.updateUser.user
            const currentCurrentUser = JSON.parse(localStorage.currentUser); // to keep the token
            if (currentCurrentUser.id === user.id){
                localStorage.setItem('currentUser', JSON.stringify({ ...currentCurrentUser, ...user, first_name: user.firstName, last_name: user.lastName}));
            }
            apolloClient.resetStore()
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const columns = [
        {
            title: <Typography.Text strong>氏名</Typography.Text>,
            dataIndex: 'fullName'
        },
        {
            title: <Typography.Text strong>メール</Typography.Text>,
            dataIndex: 'email',
            render: (email, record) => (
                <EmailEditButton userId={record.userId} email={email} updateUser={updateUser} />
            )
        },
        {
            dataIndex: 'passwordReset',
            className: 'align-right'
        }
    ];

    if (JSON.parse(localStorage.currentUser).admin) {
        columns.push({
            dataIndex: 'department',
            title: <Typography.Text strong>事業部名</Typography.Text>,
            width: 200,
            render: (text, record) => {
                return <Select
                    defaultValue={text}
                    style={{ width: "100%"}}
                    onChange={(value) => {
                        updateUser({ variables: { input: { id: record.userId, department: value } } });
                    }}
                >
                    <Select.Option value="sweets" key="sweets">洋菓子事業部</Select.Option>
                    <Select.Option value="snacks" key="snacks">菓子事業部</Select.Option>
                </Select>;
            }
        })
    }

    useEffect(() => {
        if (data) {
            setRows(data.users.edges.map(
                user => ({
                    key: user.node.id,
                    fullName: `${user.node.lastName} ${user.node.firstName}`,
                    email: user.node.email,
                    userId: user.node.id,
                    department: user.node.department,
                    passwordReset: <PasswordResetButton userId={user.node.id} />
                })
            ));
        }
    }, [data]);

    if (loading) { return <Skeleton />; }

    if (error) {
        console.log(error)
        authenticationService.logout();
        history.push('/login');
        return <Skeleton />
    }

    const resetSearch = (data) => {
        if (data) {
            setRows(data.users.edges.map(
                user => ({
                    key: user.node.id,
                    fullName: `${user.node.lastName} ${user.node.firstName}`,
                    email: user.node.email,
                    userId: user.node.id,
                    department: user.node.department,
                    passwordReset: <PasswordResetButton userId={user.node.id} />
                })
            ));
        }
    };

    const handleSearch = searchField => {
        if (searchField.target.value === '') {
            resetSearch(data);
        } else {
            setRows(
                data.users.edges.map(
                    user => {
                        if (user.node.lastName.includes(searchField.target.value) ||
                            user.node.firstName.includes(searchField.target.value)) {
                            return {
                                key: user.node.id,
                                fullName: `${user.node.lastName} ${user.node.firstName}`,
                                email: user.node.email,
                                userId: user.node.id,
                                department: user.node.department,
                                passwordReset: <PasswordResetButton userId={user.node.id} />
                            }
                        } else {
                            return false;
                        }
                    }
                ).filter(Boolean)
            )
        }
    };

    const TableHeader = <React.Fragment>
        <Row>
            <Col align='left'>
                <Input.Search onChange={handleSearch} allowClear placeholder="検索" />
            </Col>
            <Col style={{flex: 1}} align='right'>
                <Space>
                    <Button onClick={ () => setCurrentModal('addUser') } icon={<PlusOutlined/>}>新規追加</Button>
                    <DeleteUsersButton
                        selectedUserIds={selectedUserIds}
                        handleCompleted={ (usersInfo) => { setCurrentModal('table'); setUsersDeleted(usersInfo); refetch(); }} />
                </Space>
            </Col>
        </Row>
        <Row>
            { newUserCreated &&
                <>
                    <Col span={24}>
                        <Alert message="新規ユーザーを追加しました。" type="success" showIcon closable/>
                    </Col>
                    <NewUserModal userInfo={newUserCreated} />
                </>
            }
            { usersDeleted &&
                <>
                    <Col span={24}>
                        <Alert
                            message={`ユーザーID "${usersDeleted.map(user => user.email)}"を削除しました。`}
                            type="success"
                            showIcon
                            closable
                        />
                    </Col>
                </>
            }
        </Row>
    </React.Fragment>;

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedUserIds(selectedRows.map(row => row.userId));
        }
    };

    return <React.Fragment>
        { currentModal === 'table' &&
            <Table
                columns={columns}
                dataSource={rows}
                rowSelection={{ type: 'checkbox', ...rowSelection }}
                title={() => TableHeader}
            />
        }
        { currentModal === 'addUser' &&
            <AddUserModal
                handleCompleted={ (userInfo) => { setCurrentModal('table'); setNewUserCreated(userInfo); refetch(); } }
                returnToTable={returnToTable}
            />
        }
    </React.Fragment>;
};

export default UserManagement;
