import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message } from 'antd';
import { DELETE_FORWARDING_OFF_PLANS } from '../../../mutations';
import moment from 'moment';

const DeleteForwardingOffPlansButton = ({ selectedOffDay, handleCompleted }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [deleteForwardingOffPlan] = useMutation(DELETE_FORWARDING_OFF_PLANS, {
        onCompleted: () => handleCompleted(),
        onError: (error) => {
            console.log(error);
        }
    });

    const showPopconfirm = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        deleteForwardingOffPlan({ variables: { input: { offDays: moment(selectedOffDay.start).format('YYYY-MM-DD') } } })
        .then(() => {
            setConfirmLoading(false);
            setVisible(false);
            message.success('選択した廻送計画を削除しました。');
        })
        .catch(() => {
            setConfirmLoading(false);
            setVisible(false);
            message.error('選択した廻送計画を削除できませんでした。');
        });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Popconfirm
            title='削除してもよろしいですか。'
            cancelText='キャンセル'
            okText='削除する'
            open={visible}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
            placement='topRight'
        >
            <Button
                onClick={showPopconfirm}
                icon={<DeleteOutlined/>}
            />
        </Popconfirm>
    );
};

export default DeleteForwardingOffPlansButton;
