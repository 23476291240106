import React, { useEffect, useMemo, useState } from 'react';
import {
    Row,
    Col,
    Divider,
    Typography,
    Layout,
    Spin,
    DatePicker,
    Select,
    Button,
    Space,
    Empty,
    Alert,
    Tooltip,
    Tag
} from 'antd';
import { ExportOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import PredictionsChart from './PredictionsChart';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_FACTORY_NOUHIN_COUNT, GET_FACTORY_PREDS_COUNT, GET_ALL_ITEMS, GET_NOUHIN_BY_DATE_RANGE, GET_PRED_BY_DATE_RANGE, GET_INDIVIDUAL_PREDS_COUNTS, GET_FAVORITES, GET_USER_TAGS, GET_ITEM_TAGS } from '../../queries';
import { FACTORIES } from '../../constants';
import { isSearchMatch } from '../../Utilities/isSearchMatch';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/ja_JP';
import { getDatesBetween } from '../../Utilities/date';
import * as XLSX from 'xlsx';
import { useStickyState } from '../../Utilities/useStickyState';
import { filterRowsByQueryAndFilterParams } from '../../Utilities/listFilter';

const { Option } = Select;

const FactoryEstimation = ({ shouldRefetch }) => {
    const [factoryName, setFactoryName] = useStickyState([], 'preferredFactories')

    const today  = new Date();
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const [dateRange, setDateRange] = useState([today, endOfMonth]);
    const [selectedItemIds, setSelectedItemIds] = useState([]);
    const [showSelectFavorite, setShowSelectFavorite] = useState(true);
    const [selectedFavorites, setSelectedFavorites] = useState(false);
    const [exportDateRangeError, setExportDateRangeError] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [getPredsCountsForExport, { loading }] = useLazyQuery(GET_INDIVIDUAL_PREDS_COUNTS, { 
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const order = ["埼玉工場", "野木工場", "泉佐野工場", "吉野ヶ里工場", "札幌工場", "N/A"]
            // sort by factory => item => date
            const sheet_data = data.individualNouhinPredCounts.counts_and_preds.sort((a, b) => {
                if (order.indexOf(a.factory) < order.indexOf(b.factory)) {
                    return -1;
                }
                if (order.indexOf(a.factory) > order.indexOf(b.factory)) {
                    return 1;
                }
                if (a.item_name < b.item_name) {
                    return -1;
                }
                if (a.item_name > b.item_name) {
                    return 1;
                }
                if (a.date < b.date) {
                    return -1;
                }
                if (a.date > b.date) {
                    return 1;
                }
                return 0;
            }).concat(data.individualNouhinPredCounts.sums)
            const headerInJapanese = selectedItemIds.length === 0 
                                    ? {factory: "工場名", date: "日付", dow: "曜日", count: "納品個数", pred: "予測個数"} 
                                    : {factory: "工場名", item_name: "アイテム名", date: "日付", dow: "曜日", count: "納品個数", pred: "予測個数"}
            const header = selectedItemIds.length === 0 
                            ? ["factory", "date", "dow", "count", "pred"] 
                            : ["factory", "item_name", "date", "dow", "count", "pred"]
            sheet_data.unshift(headerInJapanese)
            const workbook = XLSX.utils.book_new(); 
            const worksheet = XLSX.utils.json_to_sheet(sheet_data, { header, skipHeader: true });
            XLSX.utils.book_append_sheet(workbook, worksheet); 
            XLSX.writeFileXLSX(workbook, (factoryName.length === 0 ? '全ての工場' : factoryName.join('_')) + '_nouhin_count.xlsx')
        }
    });

    const currentUser = useMemo(() => {
        return JSON.parse(localStorage.getItem('currentUser'))
    }, [])

    const { data: favorites} = useQuery(GET_FAVORITES, { variables: { userId: currentUser.id } });

    const selectFavorites = () => {
        if (!selectedFavorites) {
            setSelectedFavorites(true)
        } else {
            setSelectedFavorites(false)
        }
    }
    const { data: factoryNouhinData, loading: factoryNouhinLoading, refetch: refetch_nouhin_count } = useQuery(GET_FACTORY_NOUHIN_COUNT, {
        variables: { factoryNames: FACTORIES, itemIds: null }
    });

    const { data: factoryPredData, loading: factoryPredLoading, refetch: refetch_preds_count } = useQuery(GET_FACTORY_PREDS_COUNT, {
        variables: { factoryNames: FACTORIES, itemIds: null }
    });

    const { data: rawData, allItemsLoading } = useQuery(GET_ALL_ITEMS, { errorPolicy: 'all', notifyOnNetworkStatusChange: true });
    const { data: nouhinByDateRange, loading: nouhinByDateRangeLoading, refetch: refetch_nouhin_by_date_range } = useQuery(GET_NOUHIN_BY_DATE_RANGE, {
        variables: {
            factoryName: FACTORIES,
            dateFrom: dateRange ? moment(dateRange[0]).format('YYYY-MM-DD') : "",
            dateTo: dateRange ? moment(dateRange[1]).format('YYYY-MM-DD') : ""
        }
    });
    const {data: predByDateRange, loading: predByDateRangeLoading, refetch: refetch_pred_by_date_range} = useQuery(GET_PRED_BY_DATE_RANGE, {
        variables: {
            factoryName: FACTORIES,
            dateFrom: dateRange ? moment(dateRange[0]).format('YYYY-MM-DD') : "",
            dateTo: dateRange ? moment(dateRange[1]).format('YYYY-MM-DD') : ""
        }
    })

    const { data: tags, refetch: refetchTags } = useQuery(GET_USER_TAGS, { variables: { userId: currentUser.id }, fetchPolicy: 'no-cache' });

    const {data: itemTags, refetch: refetchItemTags} = useQuery(GET_ITEM_TAGS, {variables: {userId: currentUser.id}, fetchPolicy: 'no-cache'});

    const allItems = useMemo(() => {
        if (rawData) {
            return JSON.parse(rawData.allItems)
        }
    }, [rawData])

    useEffect(() => {
        if (dateRange) {
            refetch_nouhin_by_date_range({
                    factoryName: factoryName.length === 0 ? FACTORIES : factoryName,
                    dateFrom: moment(dateRange[0]).format('YYYY-MM-DD'),
                    dateTo: moment(dateRange[1]).format('YYYY-MM-DD')
            })
            refetch_pred_by_date_range({
                factoryName: factoryName.length === 0 ? FACTORIES : factoryName,
                dateFrom: moment(dateRange[0]).format('YYYY-MM-DD'),
                dateTo: moment(dateRange[1]).format('YYYY-MM-DD')
            })
        }
    }, [dateRange, refetch_nouhin_by_date_range, refetch_pred_by_date_range, factoryName])

    const combineAndFilterItemsData = useMemo(() => {
        if (allItemsLoading || nouhinByDateRangeLoading || predByDateRangeLoading) {
            return null;
        }
        const items = new Map();
        const itemToReturnList = new Set();
        let itemToReturn = [];
        if (allItems) {
            allItems.forEach(item => {
                items.set(item.id, [item.item_name, item.item_size])
            })
        }
        nouhinByDateRange.nouhinByDateRange.forEach(item_name_id => {
            if (items.has(item_name_id)) {
                itemToReturn.push({id: item_name_id, itemName: items.get(item_name_id)[0], itemSize: items.get(item_name_id)[1]});
                itemToReturnList.add(item_name_id);
            }
        })
        predByDateRange.predByDateRange.forEach(item_name_id => {
            if (items.has(item_name_id) && !itemToReturnList.has(item_name_id)) {
                itemToReturn.push({id: item_name_id, itemName: items.get(item_name_id)[0], itemSize: items.get(item_name_id)[1]});
                itemToReturnList.add(item_name_id);
            }
        })
        itemToReturn = itemToReturn.map(item => {
            const tags = itemTags?.itemTags.filter(itemTag => parseInt(itemTag.itemName.id) === item.id)
            const formattedTags = tags?.map(t => ({id: t.id, label: t.tag.name, value: t.tag.color, tagId: t.tag.id}))
            return {...item, tags: formattedTags, favorite: favorites?.favorites?.find(favorite => parseInt(favorite.itemNameId) === item.id) ? true : false}
        }).sort((a, b) => a.favorite - b.favorite)

        if (selectedFavorites) {
            itemToReturn = itemToReturn.filter(item => item.favorite)
        }
        return itemToReturn;
    }, [allItems, nouhinByDateRange, predByDateRange, allItemsLoading, nouhinByDateRangeLoading, predByDateRangeLoading, favorites, selectedFavorites, itemTags]);

    const handleSelectChange = value => {
        const allFavoriteIds = combineAndFilterItemsData.filter(item => item.favorite).map(item => String(item.id))
        const selectedItemWithoutFavorite = selectedItemIds.filter(id => !allFavoriteIds.includes(id))
        const selectedFavorite = value.filter(id => allFavoriteIds.includes(id))
        if (value[value.length - 1] === 'all') {
            setSelectedItemIds([...selectedItemWithoutFavorite, ...allFavoriteIds])
            setShowSelectFavorite(false)
        } else {
            setSelectedItemIds(value);
            if (favorites?.favorites?.length > selectedFavorite.length) {
                setShowSelectFavorite(true)
            }
        }
    };

    const handleDateRange = (dates, dateStrings) => {
        if (!dateStrings[0]) {
            setDateRange(null);
        } else {
            setDateRange([new Date(dateStrings[0]), new Date(dateStrings[1])]);
        }
    };

    const missingData = current => {
        if (factoryNouhinData || factoryPredData) {
            const latestNouhinDate = Object.keys(factoryNouhinData?.factoryNouhinCount || {}).sort().reverse()[0];
            const latestPredDate = Object.keys(factoryPredData?.factoryPredsCount || {}).sort().reverse()[0];
            const latestDate = latestPredDate !== undefined
                               ? latestNouhinDate > latestPredDate ? latestNouhinDate : latestPredDate 
                               : latestNouhinDate;
                               
            const earliestDate = Object.keys(factoryNouhinData?.factoryNouhinCount || {}).sort()[0];
            return !(current <= moment(latestDate).endOf('day') &&
                     current >= moment(earliestDate))
        } else {
            return true;
        }
    };
    const dateRender = current => {
        const style = {};
        if (!missingData(current)) {
            style.borderBottom = '1px solid #53C71A';
        }
        return <div className="ant-picker-cell-inner" style={style}>
            {current.date()}
        </div>
    }

    const handleItemsUpdate = () => {
        const itemsSelected = selectedItemIds.length > 0;
        refetch_nouhin_count({ factoryNames: factoryName.length === 0 ? FACTORIES : factoryName, itemIds: itemsSelected ? selectedItemIds : null });
        refetch_preds_count({ factoryNames: factoryName.length === 0 ? FACTORIES : factoryName, itemIds: itemsSelected ? selectedItemIds : null });
    }

    const nouhinDates = Object.keys(factoryNouhinData?.factoryNouhinCount || {}).sort();
    const nouhinPredsDates = Object.keys(factoryPredData?.factoryPredsCount || {});
    const combinedDates = [...new Set([...nouhinDates, ...nouhinPredsDates])].sort();

    const csvRowsForDateRange = (dateRange) => {
        const rows = dateRange.map((date) => {
            const nouhinCount = factoryNouhinData?.factoryNouhinCount[date] || 0;
            const roundedPreds = factoryPredData?.factoryPredsCount[date] && nouhinDates[nouhinDates.length - 1] < date
                ? Math.round(factoryPredData?.factoryPredsCount[date])
                : "　";
            const dow = moment(date).format('ddd');
            return [date, dow, nouhinCount, roundedPreds];
        })
        .filter((row) => row[1] || row[2]);
        return rows
    }
    const generateCSVData = (header, rows) => [header, ...rows];
    const csvHeader = ["日付", "曜日", "納品個数", "予測個数"];

    const handleExport = () => {
        if (dateRange === null) {
            setExportDateRangeError(true);
            return;
        }
        if (selectedItemIds.length > 0 || factoryName.length > 0) {
            const variables = {
                factoryNames: factoryName.length === 0 ? FACTORIES : factoryName,
                dateFrom: moment(dateRange[0]).format('YYYY-MM-DD'),
                dateTo: moment(dateRange[1]).format('YYYY-MM-DD'),
                itemIds: selectedItemIds
            }
            getPredsCountsForExport({ variables })
        } else {
            const workbook = XLSX.utils.book_new(); 
            const dates = dateRange ? getDatesBetween(dateRange[0], dateRange[1]) : getDatesBetween(combinedDates[0], combinedDates[combinedDates.length - 1]);
            const csvData = generateCSVData(csvHeader, csvRowsForDateRange(dates));
            const worksheet = XLSX.utils.aoa_to_sheet(csvData, { cellDates: true }); 
            XLSX.utils.book_append_sheet(workbook, worksheet); 
            XLSX.writeFileXLSX(workbook, '全ての工場_nouhin_count.xlsx')
        }
    }

    useEffect(() => {
        if (shouldRefetch === "tags") {
            refetchTags();
            refetchItemTags()
        }
    }, [shouldRefetch, refetchTags, refetchItemTags])

    const formattedTags = useMemo(() => {
        if (tags) {
            return tags.tags.map(tag => {
                return {
                    label: tag.name,
                    value: tag.color,
                    id: tag.id
                }
            })
        }
        return []
    }, [tags])

    const handleTagChange = (tag, checked) => {
		const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
		setSelectedTags(nextSelectedTags);
        if (nextSelectedTags.length > 0) {
            setSelectedItemIds(filterRowsByQueryAndFilterParams(combineAndFilterItemsData, null, null, null, nextSelectedTags).map(item => String(item.id)))
        } else {
            setSelectedItemIds([])
        }
	};

    return <Layout style={{ padding: 24, background: '#fff' }}>
        {exportDateRangeError && <Alert message="エラー" description="日付を選択してください" type="error" showIcon closable onClose={() => setExportDateRangeError(false)} />}
        <Row style={{marginBottom: "1rem"}}>
            <Col span={4} >
                <Typography.Title level={5} data-testid='title-factory-name'>予測結果 : {factoryName.length === 0 ? '全ての工場' : factoryName.join('/')}</Typography.Title>
            </Col>
        </Row>
        <Row justify='space-between'>
            <Col>
                <Space>
                    商品：
                    <Space>
                        <Select
                            mode='multiple'
                            allowClear
                            showSearch
                            value={selectedItemIds}
                            onChange={handleSelectChange}
                            disabled={selectedTags.length > 0}
                            loading={allItemsLoading || nouhinByDateRangeLoading || predByDateRangeLoading}
                            style={{ width: 250 }}
                            className="custom-select"                            
                            placeholder={'商品名'}
                            filterOption={(input, option) => {
                                const searchString = input.trim()
                                const targetString = option.children.join('')
                                return isSearchMatch(searchString, targetString);
                            }}
                        >
                            {showSelectFavorite &&
                                <Option key='all' value='all'>
                                    {' ☆ '}全てのお気に入り商品を選択する
                                </Option>
                            }
                            {combineAndFilterItemsData && combineAndFilterItemsData.map(item => {
                                return <Option key={item.id}>
                                    {item.favorite && ' ☆ '}
                                    {item.itemName} - {item.itemSize || "バラ"}
                                </Option>
                            })}
                        </Select>
                        <Tooltip placement="top" title='お気に入り商品のみを表示する'>
                            <Button icon={selectedFavorites ? <StarFilled/> : <StarOutlined/>} onClick={selectFavorites} shape="circle" style={{ marginLeft: '1rem' }}/>
                        </Tooltip>
                    </Space>
                </Space>
            </Col>
            <Col>
                出荷拠点：
                <Select
                    mode='multiple'
                    allowClear
                    showSearch
                    defaultValue={[]}
                    value={factoryName}
                    onChange={e => setFactoryName(e)}
                    style={{ width: 250, marginRight: ".5rem" }}
                    placeholder={'出荷拠点'}
                >
                    {FACTORIES && FACTORIES.map(factory => {
                        return <Option key={factory}>
                            {factory}
                        </Option>
                    })}
                </Select>
                <Button type='primary' data-testid='update-button' onClick={handleItemsUpdate}>アップデート</Button>
            </Col>
            <Col>
                <>
                    表示期間: 
                    <DatePicker.RangePicker 
                        value={dateRange?.length === 2 ? [moment.tz(dateRange[0], 'Asia/Tokyo'), moment.tz(dateRange[1], 'Asia/Tokyo')] : []}
                        dateRender={dateRender}
                        onChange={handleDateRange} 
                        locale={locale}
                        style={{ width: 300, marginLeft: ".5rem" }}
                    />
                </>
                <Divider type='vertical' />
                <Button icon={<ExportOutlined/>} disabled={loading} data-testid='export-button' onClick={handleExport}>XLSX出力</Button>
            </Col>
        </Row>
        { formattedTags.length > 0 && <Row style={{marginTop: ".5rem"}}>
            <div style={{flex: 3, display: "flex", alignItems: "center"}} align='left'>
                <span style={{ marginRight: 8 }}>タグ:</span>
                <Space size={[0, 8]} wrap>
                    {formattedTags.map((tag) => (
                        <Tag.CheckableTag
                            style={{ backgroundColor: selectedTags.includes(tag.id) ? tag.value : 'white', border: !selectedTags.includes(tag.id) && "1px solid "+tag.value, color: selectedTags.includes(tag.id) ? 'white' : tag.value}}
                            key={tag.id}
                            checked={selectedTags.includes(tag.id)}
                            onChange={(checked) => handleTagChange(tag.id, checked)}
                        >
                            {tag.label}
                        </Tag.CheckableTag>
                    ))}
                </Space>
            </div>
        </Row>}
        {loading && 
            <Row style={{display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center', marginTop: '1rem'}}>
                <Spin/>
                <div style={{color: '#3388FF'}}>出力中…</div>
            </Row>
        }
        <Row style={{marginBottom: "1rem"}}>
            <Col span='24'>
                <div style={{ height: 500 }}>
                    {factoryNouhinLoading || factoryPredLoading ?
                        (<div style={{display:'flex', justifyContent:'center', marginTop: '10em'}}><Spin/></div>) :
                            Object.keys(factoryNouhinData?.factoryNouhinCount).length === 0
                            && Object.keys(factoryPredData?.factoryPredsCount).length === 0 ?
                                <Empty style={{ marginTop: 50 }} /> :
                                <PredictionsChart
                                    dateRange={dateRange}
                                    predictions={Object.keys(factoryPredData?.factoryPredsCount)
                                                    .filter(key => key > moment().format('YYYY-MM-DD'))
                                                    .reduce((acc, key) => {
                                                        acc[key] = factoryPredData?.factoryPredsCount[key];
                                                        return acc;
                                                    }, {})
                                                }
                                    nouhins={factoryNouhinData?.factoryNouhinCount}
                                />
                    }
                    <div style={{position: "absolute", top: "3.5rem", right: "8.5rem", display: "flex", alignItems: "center"}}>
                        {currentUser?.department === 'sweets' ? (
                            <>
                            <span className='est-data-dot'/>過去納品数
                            <span className='est-data-dot-preds'/>予測納品数
                            </>
                        ) : currentUser?.department === 'snacks' ? (
                            <>
                            <span className='est-data-dot'/>過去出荷ケース数
                            <span className='est-data-dot-preds'/>予測出荷ケース数
                            </>
                        ) : null}
                        </div>
                </div>
            </Col>
        </Row>
    </Layout>
};

export default FactoryEstimation;