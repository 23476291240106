import React, { useState, useEffect, useMemo } from 'react';
import {
    Layout,
    Divider,
    DatePicker,
    Space,
    Image,
    Descriptions,
    Typography,
    Button,
    Row,
    Col,
    Skeleton,
    Select,
    Tag
} from 'antd';
import { ExportOutlined, FileUnknownOutlined } from '@ant-design/icons';
import PredictionsChart from './PredictionsChart';
import locale from 'antd/lib/date-picker/locale/ja_JP';
import { useQuery  } from '@apollo/client';
import { GET_ITEM_NAME, GET_FACTORY_NOUHIN_COUNT, GET_FACTORY_PREDS_COUNT, GET_ITEM_CAMPAIGN_PLANS, GET_CALENDAR_EVENT, GET_FORWARDING_OFF_PLANS, GET_SNACK_SHIPMENTS } from '../../queries';
import { FACTORIES } from '../../constants';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import './itemestimation.less';
import * as XLSX from 'xlsx'
import { getDatesBetween } from '../../Utilities/date';
import { useStickyState } from '../../Utilities/useStickyState';
import { PATHS } from '../../constants';

const { Option } = Select;

const ItemEstimation = () => {
    const { itemNameId } = useParams();
    const location = useLocation();
    const history = useHistory();
    const [initialDateRange, setInitialDateRange] = useState()
    const [selectedFactories, setSelectedFactories] = useStickyState([], 'preferredFactories')
    const [graphKey, remount] = useState(false); // hacky fix for unresponsive graph, but big performance boost when changing dates/factory
    
    const fetchedFactories =  selectedFactories.length > 0 ? selectedFactories : FACTORIES

    const { data: itemDescriptionData, loading: itemDescriptionLoading } = useQuery(GET_ITEM_NAME, {
        variables: { itemNameId: parseInt(itemNameId) }
    });

    const { data: nouhinData, loading: nouhinLoading, refetch: refetch_nouhin_count } = useQuery(GET_FACTORY_NOUHIN_COUNT, {
        variables: { factoryNames: fetchedFactories, itemIds: [parseInt(itemNameId)] }
    });

    const { data: snackShipments, loading: snackLoading } = useQuery(GET_SNACK_SHIPMENTS, {
        variables: { itemNameIds: [parseInt(itemNameId)] }
    });

    const { data: nouhinPredsData, loading: nouhinPredsLoading, refetch: refetch_preds_count } = useQuery(GET_FACTORY_PREDS_COUNT, {
        variables: { factoryNames: fetchedFactories, itemIds: [parseInt(itemNameId)] }
    });

    const { data: campaignData } = useQuery(GET_ITEM_CAMPAIGN_PLANS, { 
        variables: { itemNameId: parseInt(itemNameId) }})

    const { data: calendarEventData } = useQuery(GET_CALENDAR_EVENT)

    const { data: forwardingOffPlansData } = useQuery(GET_FORWARDING_OFF_PLANS)

    const today  = new Date();
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const [historicDates, setHistoricDates] = useState([today, endOfMonth]);

    const parsedCampaignData = useMemo(() => {
        if (campaignData) {
            const nameHash = {}
            return campaignData?.itemCampaignPlans.map(campaignPlan => {
                let name = campaignPlan.campaign.campaignName
                nameHash[name] ? nameHash[name] = nameHash[name] + 1 : nameHash[name] = 1
                name = name.concat(nameHash[name] === 1 ? '' : nameHash[name])
                return {
                    ...campaignPlan,
                    id: name,
                    color: "#f5222d",
                    data: [
                        {
                            x: moment(campaignPlan.startDt).format('YYYY-MM-DD'),
                            y: 0
                        },
                        {
                            x: moment(campaignPlan.endDate).format('YYYY-MM-DD'),
                            y: 0
                        }
                    ]
                }
            })
        } else return []
    }, [campaignData])

    const parsedCalendarEventData = useMemo(() => {
        const nameHash = {}
        if (calendarEventData) {
            return calendarEventData?.allCalendarEvent.map(calendarEvent => {
            let name = calendarEvent.name
            nameHash[name] ? nameHash[name] = nameHash[name] + 1 : nameHash[name] = 1
            name = name.concat(nameHash[name] === 1 ? '' : nameHash[name])
                return {
                    ...calendarEvent,
                    id: name,
                    color: "orange",
                    data: [
                        {
                            x: moment(calendarEvent.start_date).format('YYYY-MM-DD'),
                            y: 0
                        },
                        {
                            x: moment(calendarEvent.end_date).format('YYYY-MM-DD'),
                            y: 0
                        }
                    ]
                }
            })
        } else return []
    }, [calendarEventData])

    const parsedForwardingOffPlanData = useMemo(() => {
        if (forwardingOffPlansData) {
            return forwardingOffPlansData?.forwardingOffPlans.map(plan=> {
                return {
                    id: `廻送オフ日(${plan.split('-').join('')})`,
                    color: "#f5222d",
                    data: [
                        {
                            x: plan,
                            y: 0
                        },
                        {
                            x: plan,
                            y: 0
                        }
                    ]
                }
            })
        } else return []
    }, [forwardingOffPlansData])

    useEffect(() => {
        if (location?.state?.dateRange) {
            const range = location.state.dateRange;
            // set date range to today to end of the month
            setHistoricDates([new Date(range[0]), new Date(range[1])]);
            setInitialDateRange([new Date(range[0]), new Date(range[1])])
        }
    }, [location]);

    useEffect(() => {
        refetch_nouhin_count();
        refetch_preds_count();
    }, [selectedFactories, itemNameId, refetch_nouhin_count, refetch_preds_count]);

    const handleDateRange = (dates, dateStrings) => {
        remount(key => !key);
        if (!dateStrings[0]) {
            setHistoricDates(initialDateRange)
        } else {
            setHistoricDates([new Date(dateStrings[0]), new Date(dateStrings[1])]);
        }
        
    };

    const handleSelectChange = value => {
        if (value.length === 0) {
            setSelectedFactories(FACTORIES);
        } else {
            setSelectedFactories(value);
        }
        remount(key => !key);
    };

    const department = itemDescriptionData?.itemName?.department;

    const earliestAndLatestDates = useMemo(() => {
        if (department === "snacks") {
            const latestSnackDate = Object.keys(snackShipments?.snackShipmentsGrouped || {}).sort().reverse()[0];
            const earliestSnackDate = Object.keys(snackShipments?.snackShipmentsGrouped || {}).sort()[0];

            const latestPredDate = Object.keys(nouhinPredsData?.factoryPredsCount || {}).sort().reverse()[0];
            const latestDate = latestPredDate !== undefined ? (latestSnackDate > latestPredDate ? latestSnackDate : latestPredDate) : latestSnackDate;
            return [new Date(earliestSnackDate), new Date(latestDate)];
        }

		if (nouhinData || nouhinPredsData) {
            
			const latestNouhinDate = Object.keys(nouhinData?.factoryNouhinCount || {}).sort().reverse()[0];
			const latestPredDate = Object.keys(nouhinPredsData?.factoryPredsCount || {}).sort().reverse()[0];
			const latestDate = latestPredDate !== undefined ? (latestNouhinDate > latestPredDate ? latestNouhinDate : latestPredDate) : latestNouhinDate;
			const earliestDate = Object.keys(nouhinData?.factoryNouhinCount || {}).sort()[0] || Object.keys(nouhinPredsData?.factoryPredsCount || {}).sort()[0];
			return [new Date(earliestDate), new Date(latestDate)];
		}
	}, [nouhinData, nouhinPredsData, snackShipments, department]);

    const disabledDate = current => {

        if (nouhinData || nouhinPredsData) {
            const [earliestDate, latestDate] = earliestAndLatestDates;
            return !(current <= moment(latestDate).endOf('day') &&
                     current >= moment(earliestDate))
        } else {
            return true;
        }
    };

    const csvHeader = ["アイテム名", "日付", "曜日", "納品個数", "予測個数"];
    const nouhinDates = Object.keys(nouhinData?.factoryNouhinCount || {});

    const nouhinPredsDates = Object.keys(nouhinPredsData?.factoryPredsCount || {});
    const combinedDates = [...new Set([...nouhinDates, ...nouhinPredsDates])].sort();
    const csvRowsForDateRange = (dateRange, nouhins, preds = {}) => {
        const rows = dateRange.map((date) => {
            const nouhinCount = nouhins[date] || 0;
            const roundedPreds = preds[date] === 0 ? 0 : preds[date] ? Math.round(preds[date]) : "　";
            const dow = moment(date).format('ddd');
            const itemName = itemDescriptionData?.itemName.itemName;
            return [itemName, date, dow, nouhinCount, roundedPreds];
        })
        .filter((row) => row[1] || row[2]);
        return rows
    }
    const generateCSVData = (header, rows) => [header, ...rows];

    const handleExport = () => {
        const workbook = XLSX.utils.book_new(); 
        const dateRange = historicDates ? getDatesBetween(historicDates[0], historicDates[1]) : getDatesBetween(combinedDates[0], combinedDates[combinedDates.length - 1]);
        const csvData = generateCSVData(csvHeader, csvRowsForDateRange(dateRange, nouhinData?.factoryNouhinCount, nouhinPredsData?.factoryPredsCount));
        const worksheet = XLSX.utils.aoa_to_sheet(csvData, { cellDates: true }); 
        XLSX.utils.book_append_sheet(workbook, worksheet); 
        XLSX.writeFileXLSX(workbook, (itemDescriptionData?.itemName?.itemName || '') + 'nouhin_count.xlsx') 
    }

    const handleSnackExport = () => {
        const workbook = XLSX.utils.book_new();
        const combinedDates = [...new Set([...Object.keys(snackShipments?.snackShipmentsGrouped || {}), ...Object.keys(nouhinPredsData?.factoryPredsCount || {})])].sort();
        const dateRange = historicDates ? getDatesBetween(historicDates[0], historicDates[1]) : getDatesBetween(combinedDates[0], combinedDates[combinedDates.length - 1]);
        const csvData = generateCSVData(csvHeader, csvRowsForDateRange(dateRange, snackShipments?.snackShipmentsGrouped, nouhinPredsData?.factoryPredsCount));
        const worksheet = XLSX.utils.aoa_to_sheet(csvData, { cellDates: true });
        XLSX.utils.book_append_sheet(workbook, worksheet);
        XLSX.writeFileXLSX(workbook, (itemDescriptionData?.itemName?.itemName || '') + 'snack_shipments.xlsx')
    }

    return <Layout style={{ padding: 24, background: '#fff' }}>
        <Row>
            <Col span={12} xs={24} md={24} lg={24} xl={24} xxl={12}>
                    <Typography.Title onClick={() => history.push({pathname: PATHS.addMenu, state: {searchText: itemDescriptionData?.itemName?.itemName}})} level={5}>
                       
                        {/* eslint-disable-next-line */}
                        予測結果 :  <a>
                            {itemDescriptionData?.itemName?.itemName || ''}
                        {' '}
                        { itemDescriptionData?.itemName?.userDefinedStatus === 'Regular' && <Tag color="green">定番商品</Tag> }
                        </a>
                    </Typography.Title>
            </Col>
            <Col align='right' span={12} xs={24} md={24} lg={24} xl={24} xxl={12}>
                <Space direction='horizontal'>
                    <>
                        出荷拠点:
                        <Select mode="multiple" defaultValue={selectedFactories} allowClear onChange={handleSelectChange} style={{ width: 350 }}>
                            {
                                FACTORIES.map(factory => (
                                    <Option data-testid='factory-list' key={factory} value={factory}>{factory}</Option>
                                ))
                            }
                        </Select>
                    </>
                    <>
                        表示期間:
                        <DatePicker.RangePicker 
                            value={historicDates ? [moment.tz(historicDates[0], 'Asia/Tokyo'), moment.tz(historicDates[1], 'Asia/Tokyo')] : []}
                            disabledDate={disabledDate} 
                            onChange={handleDateRange} 
                            locale={locale}
                            style={{ width: 300 }}
                        />
                    </>
                    <Divider type='vertical' />
                    <Button icon={<ExportOutlined/>} onClick={() => {
                        return (department === "snacks" ? handleSnackExport : handleExport)()
                    }}>XLSX出力</Button>
                </Space>
            </Col>
            <Col span='24'>
                { itemDescriptionData?.itemName.department === "sweets" ? <div style={{ height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {(nouhinData && Object.keys(nouhinData?.factoryNouhinCount).length === 0) &&
                     (nouhinPredsData && Object.keys(nouhinPredsData?.factoryPredsCount).length === 0) ?
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FileUnknownOutlined style={{ fontSize: 50 }} /><br/>
                            <Typography.Text strong>データがありません</Typography.Text>
                        </div> :
                        (nouhinLoading || nouhinPredsLoading) ? <Skeleton /> : <PredictionsChart
                            dateRange={historicDates || earliestAndLatestDates}
                            predictions={nouhinPredsData?.factoryPredsCount}
                            nouhins={nouhinData?.factoryNouhinCount}
                            campaignData={parsedCampaignData}
                            events={parsedCalendarEventData}
                            forwardingOffPlans={parsedForwardingOffPlanData}
                            key={graphKey}
                            />
                            
                    }
                    <div style={{position: "absolute", top: "3.5rem", right: "8.5rem", display: "flex", alignItems: "center"}}>
                        <span className='est-data-dot'/>過去納品数
                        <span className='est-data-dot-preds'/>予測納品数
                    </div>
                </div> :
                    <div style={{ height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {(snackShipments && Object.keys(snackShipments?.snackShipmentsGrouped).length === 0) ?
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FileUnknownOutlined style={{ fontSize: 50 }} /><br/>
                            <Typography.Text strong>データがありません</Typography.Text>
                        </div> :
                        snackLoading ? <Skeleton /> : <PredictionsChart
                            dateRange={historicDates || earliestAndLatestDates}
                            nouhins={snackShipments?.snackShipmentsGrouped}
                            predictions={nouhinPredsData?.factoryPredsCount}
                            campaignData={parsedCampaignData}
                            events={parsedCalendarEventData}
                            forwardingOffPlans={parsedForwardingOffPlanData}
                            key={graphKey}
                            />
                            
                    }
                    <div style={{position: "absolute", top: "3.5rem", right: "8.5rem", display: "flex", alignItems: "center"}}>
                        <span className='est-data-dot'/>過去出荷ケース数
                        <span className='est-data-dot-preds'/>予測出荷ケース数
                    </div>
                </div>
                }
            </Col>
        </Row>
        <Divider/>
        <Typography.Title level={5}>商品内容</Typography.Title>
        { itemDescriptionLoading ?
            <Skeleton /> :
            <Row type='flex' style={{ alignItems: 'center' }}>
                <Col span={20}>
                    <Descriptions className='setlabelwidth' bordered>
                        <Descriptions.Item label="商品名" span={3}>
                            {itemDescriptionData?.itemName?.itemName || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="サイズ" span={3}>
                            {itemDescriptionData?.itemName?.itemSize || 'バラ'}
                        </Descriptions.Item>
                        <Descriptions.Item label="価格（税込）" span={3}>
                            {itemDescriptionData?.itemName?.itemSellingCycles.length > 0 &&
                            `${Math.round(itemDescriptionData?.itemName?.itemSellingCycles?.at(-1).regularPrice)}円`}
                        </Descriptions.Item>
                        <Descriptions.Item label="商品説明" span={3}>
                            {itemDescriptionData?.itemName?.itemProfile?.itemDetail || ''}
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col offset={1} span={3}>
                    <Space>
                        <Image width={100} src={itemDescriptionData?.itemName?.itemProfile?.presignedDownloadUrl || ''} />
                    </Space>
                </Col>
            </Row>
        }
        { itemDescriptionData?.itemName?.userDefinedStatus !== 'Regular' &&
            <>
            <Divider/>
            <Typography.Title level={5}>類似商品</Typography.Title>
            { itemDescriptionLoading ?
                <Skeleton /> :
                <Row type='flex' style={{ alignItems: 'center' }}>
                    <Col span={20}>
                        <Descriptions className='setlabelwidth' bordered>
                            <Descriptions.Item label="商品名" span={3}>
                                {itemDescriptionData?.itemName?.itemSimilarity?.mostSimilarItemName?.itemName || ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="サイズ" span={3}>
                                {itemDescriptionData?.itemName?.itemSimilarity?.mostSimilarItemName?.itemSize || 'バラ'}
                            </Descriptions.Item>
                            <Descriptions.Item label="価格（税込）" span={3}>
                                {itemDescriptionData?.itemName?.itemSimilarity?.mostSimilarItemName?.itemSellingCycles.length > 0 &&
                                `${Math.round(itemDescriptionData?.itemName?.itemSimilarity?.mostSimilarItemName?.itemSellingCycles?.at(-1).regularPrice)}円`}
                            </Descriptions.Item>
                            <Descriptions.Item label="商品説明" span={3}>
                                {itemDescriptionData?.itemName?.itemSimilarity?.mostSimilarItemName?.itemProfile?.itemDetail || ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="類似度" span={3}>
                                {itemDescriptionData?.itemName?.itemSimilarity?.mostSimilarScore || ''}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col offset={1} span={3}>
                        <Space>
                            <Image width={100} src={itemDescriptionData?.itemName?.itemSimilarity?.mostSimilarItemName?.itemProfile?.presignedDownloadUrl || ''} />
                        </Space>
                    </Col>
                </Row>
            }
            </>
        }
    </Layout>
};

export default ItemEstimation;
