import React from "react";
import { message } from "antd";
import { useMutation } from "@apollo/client";
import { CREATE_CAMPAIGN, CREATE_CAMPAIGN_PLAN } from "../../mutations";
import CampaignForm from "./CampaignForm";
import { localTimeZoneToUTC } from '../../Utilities/date';

const AddNewCampaign = ({ handleCancel, handleFinishAdd }) => {
    const [createCampaignPlan] = useMutation(CREATE_CAMPAIGN_PLAN);
    const [createCampaign] = useMutation(CREATE_CAMPAIGN, {
        onCompleted: (data) => {
            handleFinishAdd();
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const onFinish = async (values) => {
        const {
            campaignCategoryId,
            campaignName,
            presentName,
            presentMinPrice,
            couponMinPrice,
            campaignDiscountRate,
            discountType,
            campaignPlans,
        } = values;
        try {
            const campaignResponse = await createCampaign({
                variables: {
                    input: {
                        campaignCategoryId,
                        campaignName,
                        presentName,
                        presentMinPrice,
                        couponMinPrice,
                        campaignDiscountRate:
                            discountType === "rate"
                                ? String(campaignDiscountRate / 100)
                                : null,
                        campaignDiscountPrice:
                            discountType === "price" ? campaignDiscountRate : null,
                    },
                },
            });


            const campaignId = campaignResponse?.data?.createCampaign?.campaign?.id;

            // Process each campaign plan
            if (campaignPlans && campaignPlans.length > 0) {
                for (const plan of campaignPlans) {
                    const [startDt, endDate] = plan.campaignRange; // Assuming daterange is an array with two elements: [startDt, endDate]
                    await createCampaignPlan({
                        variables: {
                            input: {
                                campaignId: campaignId,
                                startDt: localTimeZoneToUTC(startDt),
                                endDate: localTimeZoneToUTC(endDate),
                                itemIdList: plan.items
                            }
                        }
                    });
                }
                message.success("キャンペーンとすべてのキャンペーン計画を追加しました。");
            } else {
                message.success("キャンペーンを追加しました。");
            }

            handleFinishAdd();
        } catch (error) {
            console.error(error);
            message.error("キャンペーンの追加に失敗しました。");
        }
    };

    return <CampaignForm handleCancel={handleCancel} onFinish={onFinish} />;
};

export default AddNewCampaign;
