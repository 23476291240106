import React, { useState, useEffect } from 'react';
import { Spin, Layout, Space, Typography, Modal, message, Form, Select, DatePicker, Divider, Input, Button } from 'antd';
import { GET_CALENDAR_EVENT } from '../../../queries';
import { CREATE_CALENDAR_EVENT } from '../../../mutations';
import { useQuery, useMutation } from '@apollo/client';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import DeleteSpecialEventButton from './DeleteSpecialEventButton';
import moment from 'moment';
import 'moment/locale/ja'; 
import { localTimeZoneDate } from '../CampaignPlans/CampaignSchedule';
import CalendarDatePicker from '../CalendarDatePicker';
import { getFormattedDate } from '../../../Utilities/date';
import { orange } from '@ant-design/colors';
moment.locale('ja', {
    week: {
        dow: 1,
        doy: 1
    }
});
const localizer = momentLocalizer(moment);

const SpecialEventSchedule = () => {
    const [addSpecialEvent, setAddSpecialEvent] = useState(false);
    const [specialEvents, setSpecialEvents] = useState([]);
    const [eventRange, setEventRange] = useState([]);
    const [eventName, setEventName] = useState('');
    const [selectedSpecialEventDay, setSelectedSpacialEventDay] = useState(null);
    const [specialEventsShow, setSpecialEventsShow] = useState(false);
    const [eventLists, setEventLists] = useState([]);
    const [newEventName, setNewEventName] = useState('');
    const [selectedDate, setSelectedDate] = useState(
        localStorage.getItem('SpecialEventCalendarDate') ? new Date(localStorage.getItem('SpecialEventCalendarDate')) : new Date()
        );
    const { data, loading, refetch } = useQuery(GET_CALENDAR_EVENT);
    const [createSpecialEvent] = useMutation(CREATE_CALENDAR_EVENT, {
        onCompleted: (data) => {
            message.success('イベントを追加しました。');
            refetch();
            setAddSpecialEvent(false);
        },
        onError: (error) => {
            console.log(error);
            message.error('イベント追加に失敗しました。');
            
        }
    });

    const showSelectedSpecialEventDay = () => {
        setSpecialEventsShow(true);
    };
    
    const hideModal = () => {
        setSpecialEventsShow(false);
    };
    const eventStyleGetter = () => {
        const style = {
          backgroundColor: orange[2],
          borderRadius: '2px',
          opacity: 0.8,
          color: 'black',
          height: '8rem',
          border: '0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '1.5rem'
        };
        return {
          style: style
        };
    };
    const modalHeader = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography.Text strong>イベント</Typography.Text>
            <Space style={{ marginRight: 30 }}>
                <DeleteSpecialEventButton 
                    selectedSpecialEventDay={selectedSpecialEventDay}
                    handleCompleted={ () => { hideModal(); refetch(); }}
                />
            </Space>
        </div>
    );

    const sendNewSpecialEvent = () => {
        createSpecialEvent({
            variables: { 
                input: { 
                    name: eventName,
                    startDate: moment(eventRange[0]).format('YYYY-MM-DD'),
                    endDate: moment(eventRange[1]).format('YYYY-MM-DD')
                }
            }
        });
    };

    useEffect(() => {
        if (data) {
            const eventsNames = new Set();
            const events = data.allCalendarEvent.map(event => {
                eventsNames.add(event.name);
                const start = localTimeZoneDate(event.start_date);
                const end = event.end_date ? localTimeZoneDate(event.end_date) : null
                const formattedRange = event.start_date !== event.end_date ? getFormattedDate(start) + ' - ' + getFormattedDate(end) : getFormattedDate(start);
                return {
                    id: event.id,
                    allDay: true,
                    title: event.name,
                    start,
                    end: end || start,
                    formattedRange
                }
            })
            setSpecialEvents(events)
            setEventLists(Array.from(eventsNames));
        }
    }, [data]);

    return (
    <Layout style={{ padding: 24, background: '#fff' }}>
        {loading ? 
            <Spin /> : 
            <Space direction='vertical' style={{ textAlign: 'center' }}>
                <Calendar
                    localizer={localizer}
                    views={['month']}
                    events={specialEvents}
                    selectable
                    date={selectedDate}
                    endAccessor={(event) => {
                        const endDate = moment(event.end)
                        return endDate.add(1, 'days')
                    }}
                    defaultView='month'
                    style={{ height: 1100 }}
                    onSelectEvent={event => { setSelectedSpacialEventDay(event); showSelectedSpecialEventDay(); }}
                    onSelectSlot={(slotInfo) => {
                        setEventRange([slotInfo.start, slotInfo.start]);
                        setAddSpecialEvent(true);
                    }}
                    eventPropGetter={eventStyleGetter}
                    components={{
                        toolbar: ({onNavigate}) => <CalendarDatePicker
                                                        onNavigate={onNavigate}
                                                        setAddSpecialEvent={setAddSpecialEvent}
                                                        selectedDate={selectedDate}
                                                        setSelectedDate={setSelectedDate}
                                                        isCampaignSchedule={false}
                                                        isFowardingOffSchedule={false}
                                                    />
                                    }}
                    >
                </Calendar>
            </Space>}

        {selectedSpecialEventDay &&
            <Modal 
                open={specialEventsShow}
                onCancel={hideModal}
                title={modalHeader}
                footer={null}
            >
                <p>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography.Text strong style={{ width: 140 }}>日付:</Typography.Text>
                        <Typography.Text>{selectedSpecialEventDay.formattedRange}</Typography.Text>

                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography.Text strong style={{ width: 140 }}>イベント名:</Typography.Text>
                        <Typography.Text>{selectedSpecialEventDay.title}</Typography.Text>
                    </div>
                </p>
            </Modal>
        }
        {
            addSpecialEvent &&
            <Modal
                open={addSpecialEvent}
                onCancel={() => setAddSpecialEvent(false)}
                title='イベント設定'
                onOk={sendNewSpecialEvent}
                okText='追加'
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Item
                        label='イベント名'
                        name='eventName'
                        required
                        rules={[{ required: true, message: 'イベント名を入力してください。' }]}
                    >
                        <Select placeholder="イベント名" value={eventName} onChange={value => setEventName(value)}
                            options={eventLists.map(e => { return { label: e, value: e } })}
                            dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <Divider style={{ margin: '8px 0' }} />
                                  <Space style={{ padding: '0 8px 4px' }}>
                                    <Input
                                      placeholder="カスタムイベント名"
                                      value={newEventName}
                                      onChange={e => setNewEventName(e.target.value)}
                                    />
                                    <Button onClick={e => {
                                        e.preventDefault();
                                        setEventLists([...eventLists, newEventName]);
                                        setNewEventName('');
                                    }}>
                                      追加する
                                    </Button>
                                  </Space>
                                </>
                              )}/>
                    </Form.Item>
                    <Form.Item
                        label='イベント期間'
                        name='eventRange'
                        required
                        rules={[{ required: true, message: '期間が必要です' }]}
                        >
                        <DatePicker.RangePicker 
                            defaultValue={[moment(eventRange[0]), moment(eventRange[1])]}
                            value={[moment(eventRange[0]), moment(eventRange[1])]}
                            onChange={e => setEventRange(e)} />
                    </Form.Item>
                </div>
            </Modal>
        }
    </Layout>
            
    )
}

export default SpecialEventSchedule;