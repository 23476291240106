import React from 'react';
import { Divider, DatePicker, Row, Col, Button, Typography } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Navigate } from 'react-big-calendar';
import moment from 'moment'
import PdfExportButton from './CampaignPlans/PdfExportButton';

const CalendarDatePicker = ({ events, isCampaignSchedule, isFowardingOffSchedule, onNavigate, setAddingPlan, selectedDate, setSelectedDate, setAddSpecialEvent }) => {
    const onMonthChange = (date, dateString) => {
        if (!date) {
            onNavigate(Navigate.DATE, new Date());
            return;
        }

        const fullDateString = dateString + '-15'
        setSelectedDate(new Date(fullDateString));
        if (isCampaignSchedule) {
            localStorage.setItem('campaignCalendarDate', fullDateString);
        } else if (isFowardingOffSchedule) {
            localStorage.setItem('forwardingOffPlanCalendarDate', fullDateString);
        }else {
            localStorage.setItem('specialEventCalendarDate', fullDateString);
        }
        onNavigate(Navigate.DATE, new Date(fullDateString));
    }

    return (
        <div>
            <Row gutter={8} style={{ marginBottom: 24 }}>
                <Col align='left'>
                    <DatePicker picker='month' onChange={onMonthChange} value={moment(selectedDate)} allowClear={false} />
                </Col>
                {isCampaignSchedule ?
                <>
                    <Typography.Text style={{ margin: 'auto 0.5em', fontSize: '1rem'}}>キャンペーン計画</Typography.Text>
                    <Col align='right' style={{ flex: 100 }} data-html2canvas-ignore="true">
                        <PdfExportButton selectedDate={selectedDate} events={events}/>
                        <Divider type='vertical' />
                        <Button 
                            type='primary'
                            icon={<PlusCircleOutlined/>}
                            onClick={() => setAddingPlan(true)}
                            >
                            キャンペーン計画追加
                        </Button>
                    </Col> 
                </>
                : isFowardingOffSchedule ?
                <Typography.Text style={{ margin: 'auto 0.5em', fontSize: '1rem'}} data-html2canvas-ignore="true">廻送計画</Typography.Text>
                :
                <Typography.Text style={{ margin: 'auto 0.5em', fontSize: '1rem'}} data-html2canvas-ignore="true">イベント</Typography.Text>
                }
            </Row>
        </div>
    )
};

export default CalendarDatePicker;