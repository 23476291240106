import React from 'react';
import './App.less';
import { authenticationService } from './Utilities/authenticationService';
import {
    LoginForm,
    UserSettings,
    Estimations,
    AddMenu,
    ItemList,
    Campaigns,
    ItemEstimation,
    FactoryEstimation,
    PrivateRoute
} from './Components';
import { PATHS, APPLEPIE_API_ROOT }  from './constants';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost';
import { setContext } from '@apollo/client/link/context';
class App extends React.Component {
    state = {
        currentUser: null
    };

    componentDidMount = () => {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }

    render = () => {
        const { currentUser } = this.state;
        const httpLink = new HttpLink({ uri: APPLEPIE_API_ROOT + '/graphql' });
        const authLink = setContext((_, { headers }) => {
            const token = localStorage.getItem('currentUser');
            return {
                headers: {
                    ...headers,
                    Authorization: token ? `Bearer ${currentUser.token}` : ''
                }
            };
        });

        const client = new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache()
        });

        return (
            <ApolloProvider client={client}>
                <Router basename={ process.env.PUBLIC_URL }>
                    <Switch>
                        <PrivateRoute disableBorder path={ PATHS.userSettings } component={UserSettings} onLogout={this.handleLogout} componentProps={{ apolloClient: client }}/>
                        <Route path={ PATHS.login } component={LoginForm}/>
                        <PrivateRoute path={ PATHS.itemList } component={ItemList}/>
                        <PrivateRoute path={ PATHS.itemDetails } component={ItemEstimation}/>
                        <PrivateRoute path={ PATHS.estimations } component={Estimations} title="商品予測"/>
                        <PrivateRoute path={ PATHS.addMenu } component={AddMenu} title="商品管理"/>
                        <PrivateRoute path={ PATHS.campaigns } component={Campaigns}/>
                        <PrivateRoute path={ PATHS.factoryDetails } component={FactoryEstimation}/>
                        <Route render={() => <Redirect to={PATHS.userSettings} />} />
                    </Switch>
                </Router>
            </ApolloProvider>
        );
    }
}

export default App;
