import { Tabs, Layout } from 'antd';
import React from 'react';
import CampaignSchedule from './CampaignPlans/CampaignSchedule';
import ForwardingOffSchedule from './ForwardingOffPlans/ForwardingOffSchedule';
import SpecialEventSchedule from './SpecialEventPlans/SpecialEventSchedule';
import SellingItemPlans from './SellingItemPlans/SellingItemPlans';

const CalendarTab = () => {
    return <Layout>
        <Tabs tabBarStyle={{ marginBottom: 'auto', borderRadius: '5px 5px 0 0'}} type="card" defaultActiveKey='all'>
            <Tabs.TabPane tab='キャンペーン' style={{ background: '#fff', padding: '0 10px'}} key="campaign">
                <CampaignSchedule />
            </Tabs.TabPane>
            <Tabs.TabPane tab="廻送計画" style={{ background: '#fff', padding: '0 10px'}} key="forwardingOffPlans">
                <ForwardingOffSchedule />
            </Tabs.TabPane>
            <Tabs.TabPane tab="イベント" style={{ background: '#fff', padding: '0 10px'}} key="specialEvent">
                <SpecialEventSchedule />
            </Tabs.TabPane>
            <Tabs.TabPane tab="販売商品" style={{ background: '#fff', padding: '0 10px'}} key="sellingItemPlans">
                <SellingItemPlans />
            </Tabs.TabPane>
        </Tabs>
    </Layout>
}

export default CalendarTab;