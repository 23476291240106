import React, { useEffect, useState, useMemo } from 'react';
import {
	Layout,
    Space,
    Radio,
    Checkbox,
    Button,
    Input,
    InputNumber,
    Typography,
    DatePicker,
    message,
    Form,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { CREATE_ITEM } from '../../mutations';
import UploadButton from './UploadButton';

const AddNewItem = ({ handleCancel, history, refetch, setAddingItem }) => {
    const [dupeError, setDupeError] = useState(false)
    const [itemImgFile, setItemImgFile] = useState('');
    const [imgError, setImgError] = useState(false);
    const [createItem] = useMutation(CREATE_ITEM, {
        onCompleted: (data) => {
            message.success('商品を追加しました。');
            setAddingItem(false)
            refetch()
            form.resetFields();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const currentUser = useMemo(() => {
        return JSON.parse(localStorage.getItem('currentUser'))
    }, [])

    const onFinish = ({ itemName, itemMappings, itemDetail, itemSellingCycles, itemCount, regular })  => {
        const userDefinedStatus = regular ? 'Regular' : null;
        itemCount = parseInt(itemCount);
        let size = String(itemSize);
        if (size === 'box') {
            size = String(itemCount);
        } else if (size === '') {
            size = null;
        }
        const seen = {}
        setDupeError(false)
        let dupe = false
        itemMappings.forEach(e => {
            const concatenated = e.id.toString() + (e.name || "")
            if (seen[e.id] === concatenated) {
                dupe = true
            } else {
                seen[e.id] = concatenated
            }
        })
        if (dupe) {
            setDupeError(true)
            message.error('製品コードが重複しています。', 5);
            return
        }
        const formattedItemSellingCycles = itemSellingCycles?.map((isc) => {
            return {
                price: isc.price,
                dates: [
                    isc.dates[0].format("YYYY-MM-DD"),
                    isc.dates[1].format("YYYY-MM-DD"),
                ]
            };
        });
        createItem({ variables: { input: {
            itemName, itemSize: size, itemImgFile: itemImgFile, userDefinedStatus,
            itemDetail, itemMappings, itemSellingCycles: formattedItemSellingCycles
        }}});
    };

    const [itemSize, setItemSize] = useState('');
    const [form] = Form.useForm();
    const formItemLayout = {
        labelCol:  {
            xs: { span: 24 },
            sm: { span: 24 },
            md:  { span: 24 },
            lg:  { span: 6 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md:  { span: 24 },
            lg:  { span: 8 }
        },
        layout: 'horizontal',
        labelAlign: 'right'
        
    };

    const handleRadioChange = e => {
        setItemSize(e.target.value);
    };

    useEffect(() => {
        return () => {
            if (history.action === "POP") {
                handleCancel();
            }
        }
    } , [history, handleCancel]);

    useEffect(() => {
        if (itemImgFile.length) {
            setImgError(false)
        }
    }, [itemImgFile])

    const handleSubmit = (e) => {
        form.validateFields()
        if (itemImgFile === '') {
            e.preventDefault()
            setImgError(true)
            return
        }
    }

    return <>
        <Layout style={{ padding: '24px 24px 0px 24px', background: '#fff' }}>
            <Typography.Title level={4}>商品追加</Typography.Title>
        </Layout>
        <Layout style={{ padding: 24, background: '#fff' }}>
            <Form
                form={form}
                onFinish={onFinish}
                {...formItemLayout}
                layout="horizontal"
                autoComplete="off"
            >
                <Form.Item label='商品名' name='itemName' 
                    rules={[
                        { required: true, message: '商品名が必要です。' }, 
                        { whitespace: true, message: '商品名が必要です。' }, 
                    ]}>
                    <Input placeholder="商品名" />
                </Form.Item>
                <Form.Item label='商品説明' name='itemDetail' 
                    rules={[
                        { required: true, message: '商品説明が必要です。' },
                        { whitespace: true, message: '商品説明が必要です。' },
                        { max: 1000, message: '商品説明は１０００文字以下である必要があります。' }
                    ]}>
                    <Input.TextArea placeholder="商品の説明をわかりやすく書いてください。 最大 1000文字入力可能です。" />
                </Form.Item>
				<Form.Item label='製品' name='itemMappings' rules={[{required: true, message: '製品が必要です。'}]} {...dupeError && {validateStatus: "error"}} dependencies={['itemMappings', 0]} >
					<Form.List name="itemMappings"
                    >
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, fieldKey, ...restField }) => (
									<Space key={key} align="baseline">
										<Form.Item
											{...restField}
											name={[name, 'id']}
											fieldKey={[fieldKey, 'id']}
											rules={[{ required: true, message: '製品コードが必要です。' }]}
										>
											<InputNumber
                                                formatter={value => Math.abs(parseInt(value)) || null}
                                                placeholder="製品コード"
                                                type="number"
                                            />
										</Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                fieldKey={[fieldKey, 'name']}
                                                rules={[{ required: true, message: '製品名(ｶﾅ)が必要です。' },
                                                {type: 'string', pattern: /^[\uFF61-\uFF9F0-9a-zA-Z+\-/*_~!@#$%^&(){}:;"'><.,`]+$/, message: '製品名は半角カタカナか英数字のみ入力可能です。'}]}
                                            >
                                            <Input placeholder="製品名" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'category']}
                                                fieldKey={[fieldKey, 'category']}
                                                rules={[{ required: true, message: '製品カテゴリーが必要です。' }]}
                                            >
                                                <InputNumber
                                                    formatter={value => Math.abs(parseInt(value)) || null}
                                                    placeholder="製品カテゴリー"
                                                    type="number"
                                                    style={{ width: 120 }}
                                                />
                                            </Form.Item>
										<MinusCircleOutlined onClick={() => remove(name)} />
									</Space>
								))}
								<Form.Item>
									<Button data-testid='add-product-detail' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
										製品を追加する
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Form.Item>
                <Form.Item label='販売期間 / 価格（税込）' name='itemSellingCycles' rules={[{ required: true, message: '販売期間と価格が必要です。' }]} dependencies={['itemSellingCycles', 0]}  labelWrap>
                    <Form.List name='itemSellingCycles'>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Space key={key} align="start">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'dates']}
                                            data-testid='selling-period-input'
                                            fieldKey={[fieldKey, 'dates']}
                                            rules={[{ required: true, message: '日付が必要です。' }]}
                                        >
                                            <DatePicker.RangePicker />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'price']}
                                            fieldKey={[fieldKey, 'price']}
                                            rules={[{ required: true, message: '価格が必要です。' }]}
                                        >
                                            <Input
                                                type='number'
                                                placeholder="価格" min={0}
                                                step='any' addonAfter='円'
                                                style={{ width: 130 }}
                                            />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} style={{ marginTop: 8 }} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button data-testid='add-selling-period-and-price' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        販売期間と価格を追加する
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item label="定番商品" name='regular' valuePropName="checked">
                    <Checkbox></Checkbox>
                </Form.Item>
                {currentUser?.department === 'sweets' && (
                    <>
                        <Form.Item label='サイズ' rules={[{ required: true, message: 'サイズを選択してください。' }]} required>
                            <Radio.Group onChange={handleRadioChange} value={itemSize} >
                                <Radio value={''}>バラ</Radio>
                                <Radio value={'SS'}>SS</Radio>
                                <Radio value={'S'}>S</Radio>
                                <Radio value={'M'}>M</Radio>
                                <Radio value={'L'}>L</Radio>
                                <Radio value={'box'}>箱</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label='個数' name='itemCount' required>
                            <Input type='number' defaultValue={1} min={0} addonAfter='個' style={{ maxWidth: "8rem" }} disabled={itemSize !== 'box'}/>
                        </Form.Item>
                    </>
                )}
                <Form.Item data-testid='add-image-btn' label='商品画像(ファイル形式: jpeg/pngのみ)' validateStatus={imgError && 'error'} help={imgError && '商品画像が必要です。'} rules={[{ required: true, message: '商品画像が必要です。' }]} required>
                    <UploadButton setItemImgFile={setItemImgFile} />
                </Form.Item>
                <Form.Item colon={false} label=' '>
                    <Space>
                        <Button data-testid='submit-new-item' type='primary' htmlType='submit' onClick={handleSubmit}>追加する</Button>
                        <Button onClick={handleCancel}>キャンセル</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Layout>
    </>
};

export default AddNewItem;
