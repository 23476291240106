import 'moment-timezone'
import moment from 'moment-timezone'

export const getDaysArray = (startStr, endStr) => {
    let startDt = new Date(startStr);
    let endDt = new Date(endStr);
    let days = [];
    for(let dt = startDt; dt <= endDt; dt.setDate(dt.getDate()+1)){
        days.push(new Date(dt));
    }
    return days.map(day => day.toISOString().slice(0, 10));
};

export const localTimeZoneToUTC = (m) => {
    return moment.tz([m.year(), m.month(), m.date(), m.hour(), m.minute(), m.second()], "UTC");
};

export const getDatesBetween = (startDate, stopDate) => {
    let dateArray = [];
    let currentDate = moment(startDate);
    let endDate = moment(stopDate);
    while (currentDate <= endDate) {
        dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

export const getFormattedDate = date => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dayOfWeek = date.getDay();
    const dow = ['日', '月', '火', '水', '木', '金', '土'];
    return `${month}月${day}日 ${dow[dayOfWeek]}曜日`;
};

export const isWithinSellingPeriod = (searchStart, searchEnd, rowStart, rowEnd) => (
    moment(rowStart).isSameOrBefore(moment(searchEnd)) && moment(rowEnd).isSameOrAfter(moment(searchStart))
);