import { authenticationService } from './authenticationService';

export const handleResponse = response => {
    return response.text().then(text => {
        let data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        data.token = response.headers.get('Authorization').split('Bearer ')[1];

        return data;
    });
};
