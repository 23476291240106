import React, { useState } from 'react';
import { Alert, Button, Descriptions } from 'antd';
import PasswordChangeForm from './PasswordChangeForm';
import { authenticationService } from '../../Utilities/authenticationService';

const AccountInformation = () => {
    const [passwordChangeForm, setPasswordChangeForm] = useState(window.localStorage.getItem('changePassword') === 'true');
    const [confirmedPasswordChanged, setConfirmedPasswordChanged] = useState(false);
    const fullName = `${authenticationService.currentUserValue.last_name} ${authenticationService.currentUserValue.first_name}`;
    const email = authenticationService.currentUserValue.email;

    const returnToAccountInfo = () => {
        setPasswordChangeForm(false);
    };

    return <>
        { confirmedPasswordChanged &&
            <Alert message={'パスワードを変更しました。'} type='success' showIcon closable />
        }
        { passwordChangeForm ?
            <PasswordChangeForm handleCompleted={() => {
                    setPasswordChangeForm(false)
                    setConfirmedPasswordChanged(true);
                    window.localStorage.setItem('changePassword', false);
                 }}
                 returnToAccountInfo={returnToAccountInfo}

            /> :
            <>
                <Descriptions layout='vertical'>
                    <Descriptions.Item label='メール' span={3}>{ email }</Descriptions.Item>
                    <Descriptions.Item label='氏名' span={3}>{ fullName }</Descriptions.Item>
                </Descriptions>
                <Button onClick={() => setPasswordChangeForm(true)} type="primary">パスワード変更</Button>
            </>
        }
    </>;
};

export default AccountInformation;