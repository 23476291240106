export const FACTORIES = [
    "埼玉工場",
    "野木工場",
    "泉佐野工場",
    "札幌工場",
    "吉野ヶ里工場",
    "N/A"
];

export const PATHS = {
    login: '/login',
    userSettings: '/userSettings',
    estimations: '/estimations/:itemNameId?',
    itemDetails: '/itemDetails/:itemNameId',
    itemList: '/itemList',
    campaigns: '/campaigns',
    addMenu: '/addMenu',
    factoryDetails: '/factoryDetails/:factoryName'
};

export const APPLEPIE_API_ROOT = process.env.REACT_APP_APPLEPIE_API_ROOT;
