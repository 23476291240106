import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Upload, notification } from 'antd';
import ImgCrop from 'antd-img-crop';

import { GET_PRESIGNED_UPLOAD_URL } from '../../mutations';

const UploadButton = ({ currentImgUrl, setItemImgFile }) => {
    const initialState =  currentImgUrl ? [
        {
            uid: 'current',
            status: 'done',
            url: currentImgUrl,
        },
    ] : [];

  // WARNING!!!!!
  // Yes, this is a *mutation* that's in reality just getting an object (so it should be a query).
  // It is deliberate: mutations return Promise objects, which we *need* for `beforeUpload` to get the
  // presigned URL before `handleUpload`, so it waits on it.
  const [getPresignedUploadUrl, { data }] = useMutation(GET_PRESIGNED_UPLOAD_URL);
  const [fileList, setFileList] = useState(initialState);
  const [headers, setHeaders] = useState();

  const handleFileChanged = ({ file }) => {
    if (file.status === 'removed') {
      setFileList([]);
    } else if (file.status === 'uploading') {
      setFileList([file]);
    } else if (file.status === 'done') {
      const {
          presignedUploadUrl: { uploadUrl }
      } = data;

      const newFile = {
        ...file,
        uploadUrl,
      };
      setFileList([newFile]);
      notification.success({ message: '画像が正常にアップロードされました' });
    }
  };

  const handleBeforeUpload = async ({ name, type }) => {
    // Headers are required by Amazon S3
    setHeaders({
      'x-amz-acl': 'public-read',
      'Content-Type': type,
    });
    // Fetches the Signed URL from S3 bucket
    await getPresignedUploadUrl({ variables: { objectKey: name } });
  };

  // Custom xhr upload method
  const handleUpload = async ({ onSuccess, onError, file }) => {
    const xhr = new XMLHttpRequest();
    const {
      presignedUploadUrl: { uploadUrl }
    } = data;

    // S3 requires PUT method!
    xhr.open('PUT', uploadUrl);
    xhr.onreadystatechange = async () => {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                onSuccess(null, file);
                setItemImgFile(file.name);
            } else {
                onError(xhr.responseText, xhr.response, file);
            }
        }
    };
    xhr.send(file);
  };

  // SignedRequest from s3 needs to be set as the action. This is the glue.
  const action = data?.presignedUploadUrl?.uploadUrl;

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
        src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const beforeCrop = file => {
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      notification.error({ message: 'ファイル形式またはファイル拡張子が正しくありません' });
      return false;
    }
    return true;
  };
  return (
    <ImgCrop zoom rotate shape="square" modalTitle="商品画像" modalOk="保存する" beforeCrop={(e) => beforeCrop(e)}>
        <Upload
            listType="picture-card"
            accept="image/png, image/jpeg"
            action={action}
            headers={headers}
            fileList={fileList}
            showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
            customRequest={(e) => handleUpload(e)}
            beforeUpload={(args) => handleBeforeUpload(args)}
            onChange={(e) => handleFileChanged(e)}
            onPreview={onPreview}
        >
            {fileList.length === 0 && 'ファイルを選択'}
        </Upload>
    </ImgCrop>
  );
};

export default UploadButton;
