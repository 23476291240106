import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Button } from 'antd';
import { DELETE_USERS } from '../../mutations';
import propTypes from 'prop-types';

const DeleteUsersButton = ({ selectedUserIds, handleCompleted }) => {
    const [deleteUsers] = useMutation(DELETE_USERS, {
        onCompleted: (data) => handleCompleted(data.deleteUser.users),
        onError: (error) => {
            console.log(error);
        }
    });
    const [visible, setVisible] = useState(false);

    return <Popconfirm
        title='選択したユーザーを削除してよろしいですか。'
        onConfirm={() => {
            deleteUsers({ variables: { input: { ids: selectedUserIds } } })
            setVisible(false);
        }}
        open={visible}
        okText={'削除する'}
        onCancel={() => setVisible(false)}
    >
        <Button
            onClick={() => setVisible(true)}
            icon={<DeleteOutlined/>}>
            削除
        </Button>
    </Popconfirm>
        ;
};

DeleteUsersButton.propTypes = {
    handleCompleted: propTypes.func,
    selectedUserIds: propTypes.array
};

export default DeleteUsersButton;
