import React from 'react';
import { Document, Page, View, Text, StyleSheet, PDFDownloadLink, Font, Image as PdfImage } from '@react-pdf/renderer'
import { Button, Spin } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import yu from '../../../fonts/yumin.ttf'
import moment from 'moment';
const SellingItemPlansExport = ({sellingItems, selectedDate, disabled}) => {
    const exportSellingItem = (
        <View style={{...styles.itemDetail, flexWrap: 'wrap', width: '100%', fontSize: 10}}>
        {sellingItems && sellingItems.map((item, index) => {
            return (
                <>
                    <View key={index} style={{ ...styles.item, width: '33%', alignItems: 'center', marginTop: 10}}>
                        <View style={{ width: '80%', ...styles.itemDetail }}>
                            <View style={{ width: '30%', ...styles.item, alignItems: 'center', justifyContent: 'space-around'}}>
                                { item.isNew ? 
                                    <Text style={{ backgroundColor: '#E8CE02', borderRadius: '5px', padding: '5px 5px 0px 5px'}}>新発売</Text> 
                                    :
                                    <Text style={{ backgroundColor: '#3BA0E5', borderRadius: '5px', padding: '5px 5px 0px 5px'}}>終売</Text>
                                }
                            </View>
                            <View style={{ width: '70%'}}>
                                {item.download_url ?
                                    <PdfImage src={item.download_url || ''} style={{ width: 100, height: 100}} />
                                    : <View style={{width: 100, height: 100}}><Text></Text></View>
                                }
                            </View>
                        </View>
                        <View style={{ ...styles.item, width:'80%', marginTop: 15 }}>
                            <View style={styles.itemDetail}>
                                <Text style={{ width: '30%' }}>商品名</Text>
                                <Text style={{ ...styles.itemDetailText, height: 30, fontSize: item.item_name?.item_name.length > 10 ? 8 : 10 }}>
                                    {item.item_name?.item_name}
                                </Text>
                            </View>
                            <View style={styles.itemDetail}>
                                <Text style={{ width: '30%' }}>本体売価</Text>
                                <Text style={styles.itemDetailText}>
                                    {Math.round(item.item_selling_cycle?.regular_price / 1.08)}円
                                </Text>
                            </View>
                            <View style={styles.itemDetail}>
                                <Text style={{ width: '30%' }}>税込売価</Text>
                                <Text style={styles.itemDetailText}>
                                    {item.item_selling_cycle?.regular_price}円
                                </Text>
                            </View>
                            <View style={styles.itemDetail}>
                                <Text style={{ width: '30%'}}>発売日</Text>
                                <Text style={styles.itemDetailText}>
                                    {moment(item.item_selling_cycle?.selling_start_dt).format('YYYY-MM-DD')}
                                </Text>
                            </View>
                            <View style={styles.itemDetail}>
                                <Text style={{ width: '30%' }}>終売日</Text>
                                <Text style={styles.itemDetailText}>
                                    {moment(item.item_selling_cycle?.selling_end_dt).format('YYYY-MM-DD')}
                                </Text>
                            </View>
                            <View style={styles.itemDetail}>
                                <Text style={{ width: '30%' }}>入数</Text>
                                <Text style={styles.itemDetailText}>
                                    {item.item_name?.per_box}
                                </Text>
                            </View>
                            <View style={styles.itemDetail}>
                                <Text style={{ width: '30%'}}>業務</Text>
                                <Text style={styles.itemDetailText}>
                                    {item.item_name?.work_type}
                                </Text>
                            </View>
                        </View>
                    </View>
                    { (index + 1) % 9 === 0 && <Text style={{ width: '100%', marginBottom: 60, textAlign: 'center'}}></Text> }
                </>
            )
        })}
        </View>
    )
    const doc = (
        <Document>
            <Page style={styles.body}>
                <View style={styles.header}>
                    <Text style={styles.headerText}>
                        {moment(selectedDate).format('YYYY年M月')}  販売商品
                    </Text>
                </View>
                <View>
                    {exportSellingItem}
                </View>
            </Page>
        </Document>
    );

    return (
        <PDFDownloadLink document={doc} fileName={`${moment(selectedDate).format('YYYY-M')}_販売商品.pdf`}>
            {({ loading, error }) => {
                if (error) {
                    return ''
                }
                return loading ? (
                    <Spin/>
                ) : (
                    <Button icon={<ExportOutlined/>} disabled={disabled}>PDF出力</Button>
                )
            }}
        </PDFDownloadLink>
    )
}

export default SellingItemPlansExport;


const styles = StyleSheet.create({
    body: {
        padding: 10,
        fontFamily: 'Yu Mincho',
        fontWeight: 400,
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px auto 0px'
    },
    headerText: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    item: {
        display: 'flex',
        flexDirection: 'column'
    },
    itemDetail: {
        display: 'flex',
        flexDirection: 'row',
    },
    itemDetailText: {
        width: '70%',
        textAlign: 'center'
    },
})

Font.register({
    family: 'Yu Mincho',
    src: yu
})