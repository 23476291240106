import React, { useState, useEffect, useMemo } from 'react';
import {
    Layout,
    Button,
    Input,
    Typography,
    DatePicker,
    Divider,
    Row,
    Col,
    Spin,
    Table,
    Tabs,
    Popover,
    Space,
    Tag,
    Select
} from 'antd';
import { PATHS } from '../../constants';
import { ExportOutlined, CheckCircleOutlined, StarTwoTone, StarOutlined } from '@ant-design/icons';
import locale from 'antd/lib/date-picker/locale/ja_JP';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ITEM_NAMES_REDUCED, GET_FAVORITES, GET_USER_TAGS, GET_ITEM_TAGS } from '../../queries';
import { CREATE_FAVORITE, DELETE_FAVORITE, CREATE_ITEM_TAG, DELETE_ITEM_TAG } from '../../mutations';
import moment from 'moment';
import { filterRowsByQueryAndFilterParams } from '../../Utilities/listFilter';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import FactoryEstimation from './FactoryEstimation';
import { PlusOutlined } from '@ant-design/icons';
import SnackMonthly from './SnackMonthly';

export const Tags = ({tagList, currentTags = [], tagItem, untagItem, itemNameId}) => {
    const [open, setOpen] = useState(false)
    const handleTagChange = (newTag) => {
        tagItem({variables: {input: {itemNameId: itemNameId, tagId: newTag}}})
        setOpen(false)
    }

    const handleTagDelete = (id) => {
        untagItem({variables: {input: { id }}})
    }

    return (
        <div style={{display: "inline"}}>
            {currentTags.map(c => (<Tag color={c.value} key={c.id} style={{marginRight: 3}} closable onClose={() => handleTagDelete(c.id)}>{c.label}</Tag>))}
           { open ?
            <Select
                showArrow
                onChange={handleTagChange}
                style={{width: "120px", marginLeft: "4px", marginTop: "4px"}}
                size="small"
                onBlur={()=> setOpen(false)}
            >
                {tagList.map(c => (
                <Select.Option value={c.id} key={c.value}>
                    <Tag
                        color={c.value}
                        style={{
                            marginRight: 3,
                        }}>
                        {c.label}
                    </Tag>
                </Select.Option>))}
            </Select> : <Button size="small" style={{marginTop: "4px"}} type="text"icon={<PlusOutlined/>} onClick={() => setOpen(true)}/>}
        </div>
    )
}

const Estimations = () => {
    const [searchText, setSearchText] = useState('');
    const [dateRange, setDateRange] = useState(null);
    const [filterFavorites, setFilterFavorites] = useState(false);
    const { data, loading, refetch } = useQuery(GET_ITEM_NAMES_REDUCED, { fetchPolicy: 'network-only', variables: { search: `department = ${JSON.parse(localStorage.currentUser)?.department}` } });
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(parseInt(localStorage.getItem('estimationsPage')) || 1);
    const [pageSize, setPageSize] = useState(parseInt(localStorage.getItem('estimationsPageSize')) || 10);
    // itemsRefetchFlag gets set to 1 in ItemList when an item is hidden/unhidden
    const [refetchFlag, setRefetchFlag] = useState(parseInt(localStorage.getItem('itemsRefetchFlag')) || 0);
    const [selectedTags, setSelectedTags] = useState([]);

    if (refetchFlag === 1) {
        refetch();
        localStorage.setItem('itemsRefetchFlag', 0);
        setRefetchFlag(0);
    }

    const linkify = rowContent => (
        <Link to={{
            pathname: PATHS.itemDetails.replace(':itemNameId', parseInt(rowContent.key)),
            state: { dateRange: dateRange ? [dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')] : dateRange }
        }}>
            予測する
        </Link>
    );

    const currentUser = useMemo(() => {
        return JSON.parse(localStorage.getItem('currentUser'))
    }, [])

    const { data: favorites, refetch: refetchFavorites } = useQuery(GET_FAVORITES, { variables: { userId: currentUser.id } });

    const { data: tags, refetch: refetchTags } = useQuery(GET_USER_TAGS, { variables: { userId: currentUser.id } });

    const {data: itemTags, refetch: refetchItemTags} = useQuery(GET_ITEM_TAGS, {variables: {userId: currentUser.id}, fetchPolicy: 'network-only'});

    if (localStorage.getItem('refetchTags') === 'true') {
        refetchTags();
        localStorage.setItem('refetchTags', false);
    }

    const formattedTags = useMemo(() => {
        if (tags) {
            return tags.tags.map(tag => {
                return {
                    label: tag.name,
                    value: tag.color,
                    id: tag.id
                }
            })
        }
        return []
    }, [tags])

    const [createFavorite] = useMutation(CREATE_FAVORITE, {
        onCompleted: () => {
            refetchFavorites();
        },
        onError: (error) => {
            console.log(error);
        }
    })

    const [deleteFavorite] = useMutation(DELETE_FAVORITE, {
        onCompleted: () => {
            refetchFavorites();
        },
        onError: (error) => {
            console.log(error);
        }
    })

    const [tagItem] = useMutation(CREATE_ITEM_TAG, {
        onCompleted: () => {
            refetchItemTags();
        },
        onError: (error) => {
            console.log(error);
        }
    })

    const [untagItem] = useMutation(DELETE_ITEM_TAG, {
        onCompleted: () => {
            refetchItemTags();
        },
        onError: (error) => {
            console.log(error);
        }
    })

    useEffect(() => {
        if (data) {
            const parsedRows = data.itemNames.edges.map(
                itemName => {
                    const latestCycle =
                        itemName.node.itemSellingCycles.length > 0
                            ? itemName.node.itemSellingCycles.reduce(
                                  (prev, curr) =>
                                      curr.sellingEndDt > prev?.sellingEndDt
                                          ? curr
                                          : prev
                              )
                            : null;
                    const isFavorite = favorites?.favorites?.map(favorite => favorite.itemNameId).includes(itemName.node.id);
                    const tags = itemTags?.itemTags.filter(itemTag => itemTag.itemName.id === itemName.node.id)
                    const formattedTags = tags?.map(t => ({id: t.id, label: t.tag.name, value: t.tag.color, tagId: t.tag.id}))
                    return {
                        key: itemName.node.id,
                        name: itemName.node.itemName,
                        size: itemName.node.itemSize || "",
                        hidden: itemName.node.hidden,
                        sellingPeriod:
                            itemName.node.itemSellingCycles?.length > 0
                                ? {
                                      period: `${
                                          latestCycle.sellingStartDt.split("T")[0]
                                      } - ${
                                          latestCycle.sellingEndDt.split("T")[0]
                                      }`,
                                      start: latestCycle.sellingStartDt.split("T")[0],
                                      end: latestCycle.sellingEndDt.split("T")[0],
                                  }
                                : null,
                        latestCycle: latestCycle,
                        latestPrice: latestCycle?.regularPrice,
                        status: itemName.node.userDefinedStatus
                            ? itemName.node.userDefinedStatus
                            : itemName.node.status,
                        favorite: isFavorite,
                        tags: formattedTags
                    };
                }
            ).filter(item => !item.hidden);
            setRows(parsedRows);
            setOriginalRows(parsedRows);
        }
    }, [data, favorites, itemTags]);

    useEffect(() => {
        setRows(filterRowsByQueryAndFilterParams(originalRows, searchText, filterFavorites, dateRange, selectedTags))
    }, [originalRows, dateRange, searchText, filterFavorites, setRows, selectedTags]);

    
    const handleTagChange = (tag, checked) => {
		const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
		setSelectedTags(nextSelectedTags);
	};


    const columns = [
        {
            title: <Typography.Text strong></Typography.Text>,
            dataIndex: 'favorite',
            width: 50,
            render: (favorite, row) => {
                return !favorite ? <StarOutlined style={{fontSize: "20px", color: "lightgrey"}} onClick={e => createFavorite({ variables: { input: { itemNameId: row.key}}})} />
                : <StarTwoTone style={{fontSize: "20px"}} onClick={e => deleteFavorite({ variables: { input: { itemNameId: row.key }}})} />
            }
        },
        {
            title: <Typography.Text strong>タグ</Typography.Text>,
            dataIndex: 'tags',
            width: 100,
            render: (tags, row) => {
                return <Tags currentTags={tags} tagList={formattedTags} tagItem={tagItem} untagItem={untagItem} itemNameId={row.key}/>
            }
        },
        {
            title: <Typography.Text strong>商品名</Typography.Text>,
            dataIndex: 'name',
            width: 450
        }, {
            title: <Typography.Text strong>サイズ</Typography.Text>,
            dataIndex: 'size',
            sorter: (a, b) => a.size.localeCompare(b.size),
            render: x => (x || 'バラ'),
            width: 100
        }, {
            title: <Typography.Text strong>価格（税込）</Typography.Text>,
            dataIndex: 'latestPrice',
            sorter: (a, b) => {
                if (a.latestPrice && b.latestPrice) {
                    return a.latestPrice - b.latestPrice;
                } else if (a.latestPrice) {
                    return 1;
                } else if (b.latestPrice) {
                    return -1
                } else {
                    return 0;
                }
            },
            // sortDirections: ['descend'],
            render: x => (x ? `¥${parseInt(x).toLocaleString('ja-JP')}` : '-'),
            align: 'right',
            width: 100
        },
        {
            title: <Typography.Text strong>定番商品</Typography.Text>,
            dataIndex: 'status',
            sorter: (a, b) => ((a.status === b.status) ? 0 : a.status === "Regular" ? -1 : 1),
            render: x => (x === "Regular" ? <CheckCircleOutlined style={{fontSize: "1rem", marginLeft: "40%"}}/> : null),
            width: 100
        }, {
            title: <Typography.Text strong>販売期間</Typography.Text>,
            dataIndex: 'sellingPeriod',
            sorter: (a, b) => {
                if (a.sellingPeriod?.end && b.sellingPeriod?.end) {
                    if (a.sellingPeriod.end === b.sellingPeriod.end) {
                        if (a.sellingPeriod.start === b.sellingPeriod.start) {
                            return 0
                        } else {
                            return a.sellingPeriod.start > b.sellingPeriod.start ? 1 : -1
                        }
                    } else {
                        return a.sellingPeriod.end > b.sellingPeriod.end ? 1 : -1
                    }
                } else if (a.sellingPeriod?.end) {
                    return 1
                } else if (b.sellingPeriod?.end) {
                    return -1
                } else {
                    return 0
                }
            },
            render: x => x?.period,
            width: 150
        }, {
            title: '',
            render: (text, rowContent) => linkify(rowContent),
            width: 150
        }
    ];

    const handleSearch = e => {
        setSearchText(e.target.value);
    };

    const handleDateRange = (dates, dateStrings) => {
        if (dateStrings.every(date => date)) {
            setDateRange([moment(dateStrings[0]), moment(dateStrings[1])]);
        } else {
            setDateRange(null);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem('estimationsPage', page);
    };

    const handlePageSizeChange = (page, size) => {
        setPageSize(size);
        localStorage.setItem('estimationsPageSize', size);
    };

    const csvHeader = [ "ID", "商品名", "サイズ", "販売期間", "価格", "定番商品", ];
    const csvRowsForData = (data) => {
        const rows = data.map((row) => {
            return [
                row.key,
                row.name,
                row.size,
                row.sellingPeriod?.period,
                row.latestPrice ? Math.round(row.latestPrice) : "",
                row.status === "Regular" ? "◯" : "",
            ];
        });
        return rows;
    };

    const generateCSVData = (header, rows) => [header, ...rows];

    const handleExport = () => {
        const workbook = XLSX.utils.book_new(); 
        const csvData = generateCSVData(csvHeader, csvRowsForData(rows));
        const worksheet = XLSX.utils.aoa_to_sheet(csvData, { cellDates: true }); 
        XLSX.utils.book_append_sheet(workbook, worksheet); 
        XLSX.writeFileXLSX(workbook, '商品予測一覧.xlsx') 
    }

    const helpContent = <>
    <b>＜サイズで検索する場合＞</b>
    <p>半角英字でスペースを空けずに「size:S/M/L」と入力してください。
        <br></br>入力例：size:S、size:M、size:L、size:バラ</p>　
</>;

    const EstimationsTable = <Layout style={{ padding: 24, background: '#fff' }}>
        <Row gutter={[16,8]}>
            <Col>
                <Input.Search autoFocus style={{ width: '600px' }} value={searchText} onChange={handleSearch} allowClear placeholder="検索" />
                <Button type='link'>
                    <Popover title='商品の検索方法' trigger='click' content={helpContent}>
                            商品の検索方法
                    </Popover>
                </Button>
            </Col>
            <Col align='left'>
                <Button 
                    onClick={() => {
                        setFilterFavorites((prev => !prev))
                    }} 
                    type='primary' 
                    >
                    {filterFavorites ? '全て表示' : 'お気に入りのみ表示'}
                </Button>
            </Col>
            <Col align='right'>
                <Space direction='horizontal'>
                     販売期間:
                    <DatePicker.RangePicker
                        locale={locale}
                        value={dateRange}
                        onChange={handleDateRange}
                    />
                    <Divider type='vertical' />
                    <Button icon={<ExportOutlined/>} onClick={handleExport}>XLSX出力</Button>
                </Space>
            </Col>
        </Row>
       { formattedTags.length > 0 && <Row style={{marginTop: ".5rem"}}>
            <div style={{flex: 3, display: "flex", alignItems: "center"}} align='left'>
                <span style={{ marginRight: 8 }}>タグ:</span>
                <Space size={[0, 8]} wrap>
                    {formattedTags.map((tag) => (
                        <Tag.CheckableTag
                            style={{ backgroundColor: selectedTags.includes(tag.id) ? tag.value : 'white', border: !selectedTags.includes(tag.id) && "1px solid "+tag.value, color: selectedTags.includes(tag.id) ? 'white' : tag.value}}
                            key={tag.id}
                            checked={selectedTags.includes(tag.id)}
                            onChange={(checked) => handleTagChange(tag.id, checked)}
                        >
                            {tag.label}
                        </Tag.CheckableTag>
                    ))}
                </Space>
            </div>
        </Row>}
        <br/>
        { loading ?
            <Spin /> :
            <Table
                columns={columns}
                dataSource={rows}
                pagination={{
                    pageSize: pageSize,
                    current: currentPage,
                    onChange: handlePageChange,
                    onShowSizeChange: handlePageSizeChange
                }}
            />
        }
    </Layout>;


    return <Layout>
        <Tabs tabBarStyle={{ paddingLeft: '24px', background: '#fff' }} defaultActiveKey='allItems'>
            {currentUser?.department === 'sweets' && <Tabs.TabPane tab='全ての商品' style={{ paddingLeft: 24, paddingRight: 24 }} key='allItems'>
                { EstimationsTable }
            </Tabs.TabPane>}
            <Tabs.TabPane tab={currentUser?.department === 'sweets' ? '全ての工場' : '営業所'} style={{ paddingLeft: 24 }} key='allFactories'>
                {currentUser.department === 'sweets' ? <FactoryEstimation /> : <SnackMonthly />}
            </Tabs.TabPane>
        </Tabs>
    </Layout>
};

export default Estimations;
