import React from "react";
import { message } from "antd";
import { useMutation } from "@apollo/client";
import { UPDATE_CAMPAIGN, UPDATE_CAMPAIGN_PLAN, CREATE_CAMPAIGN_PLAN, DELETE_CAMPAIGN_PLANS } from "../../mutations";
import CampaignForm from "./CampaignForm";
import { localTimeZoneToUTC } from '../../Utilities/date';

const EditCampaign = ({ handleCancel, campaign, handleFinishEdit }) => {
    const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
        onCompleted: (data) => {
            message.success("キャンペーンを編集しました。");
            handleFinishEdit();
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const [updateCampaignPlans] = useMutation(UPDATE_CAMPAIGN_PLAN, {
        onCompleted: (data) => {
            message.success("キャンペーン計画を編集しました。");
            handleFinishEdit();
        },
        onError: (error) => {
            console.log(error);
        },
    });
    const [createCampaignPlan] = useMutation(CREATE_CAMPAIGN_PLAN);
    const [deleteCampaignPlans] = useMutation(DELETE_CAMPAIGN_PLANS);
    const onFinish = (values, initialValues) => {
        const {
            campaignCategoryId,
            campaignName,
            presentName,
            presentMinPrice,
            couponMinPrice,
            campaignDiscountRate,
            discountType,
            campaignPlans
        } = values;
        const campaignPlanIds = initialValues.campaignPlans?.map(plan => parseInt(plan.id)).sort();
        const newCampaignPlanIds = campaignPlans?.map(plan => parseInt(plan.id)).sort();
        const removingPlanIds = campaignPlanIds.filter(id => !newCampaignPlanIds.includes(id));
        removingPlanIds.length > 0 && deleteCampaignPlans({ variables: { input: { ids: removingPlanIds } } })

        updateCampaign({
            variables: {
                input: {
                    id: campaign.id,
                    campaignCategoryId,
                    campaignName,
                    presentName,
                    presentMinPrice,
                    couponMinPrice,
                    campaignDiscountRate:
                        discountType === "rate"
                            ? String(campaignDiscountRate / 100)
                            : null,
                    campaignDiscountPrice:
                        discountType === "price"
                            ? String(campaignDiscountRate)
                            : null,
                },
            },
        });
        if (campaignPlans && campaignPlans.length > 0) {
            for (const plan of campaignPlans) {
                // create one if plan.id is null
                const [startDt, endDate] = plan.campaignRange; // Assuming daterange is an array with two elements: [startDt, endDate]
                if (!plan.id) {
                    createCampaignPlan({
                        variables: {
                            input: {
                                campaignId: campaign.id,
                                startDt: localTimeZoneToUTC(startDt),
                                endDate: localTimeZoneToUTC(endDate),
                                itemIdList: plan.items
                            }
                        }
                    });
                } else {
                    updateCampaignPlans({
                        variables: {
                            input: {
                                id: parseInt(plan.id),
                                campaignId: campaign.id,
                                startDt: localTimeZoneToUTC(startDt),
                                endDate: localTimeZoneToUTC(endDate),
                                itemIdList: plan.items,
                            },
                        },
                    });
                }
            }
        }
    };

    return (
        <CampaignForm
            handleCancel={handleCancel}
            onFinish={onFinish}
            campaign={campaign}
        />
    );
};

export default EditCampaign;
