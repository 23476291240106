import React from 'react';
import { Typography, Layout, Tabs, Card, Space } from 'antd';
import { UserOutlined, TeamOutlined, SettingOutlined } from '@ant-design/icons';
import AccountInformation from './AccountInformation';
import UserManagement from './UserManagement';

const { Content } = Layout;

const UserSettings = ({ apolloClient }) => {
    return <Layout>
        <Space direction='vertical' style={{ width: '100%' }}>
        <Card title={<Typography.Title level={4}>設定</Typography.Title>}>
        <Content className='site-layout-background'>
            <Tabs tabPosition='left' defaultActiveKey={'accountInformation'}>
                <Tabs.TabPane tab={<><UserOutlined/>アカウント</>} key='accountInformation' icon={<SettingOutlined />}>
                    <AccountInformation/>
                </Tabs.TabPane>
                { JSON.parse(localStorage.currentUser).admin &&
                    <Tabs.TabPane tab={<><TeamOutlined/>ユーザー管理</>} key='userManagement' icon={<TeamOutlined />}>
                    <UserManagement apolloClient={apolloClient} />
                </Tabs.TabPane> }
            </Tabs>
        </Content>
        </Card>
        </Space>
    </Layout>;
};

export default UserSettings;
