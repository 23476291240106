import gql from 'graphql-tag';

export const CHANGE_PASSWORD = gql`
mutation changePassword($input: changePasswordUserInput!) {
    changePasswordUser(input: $input) {
        user {
            id
            email
            password
        }
    }
}
`;


export const CREATE_USER = gql`
mutation createUser($input: createUserInput!) {
    createUser(input: $input) {
        user {
            id
            firstName
            lastName
            email
            department
            password
        }
    }
}
`;

export const UPDATE_USER = gql`
mutation updateUser($input: updateUserInput!) {
    updateUser(input: $input) {
        user {
            id
            firstName
            lastName
            email
            department
        }
    }
}
`;

export const CREATE_ITEM = gql`
mutation createItem($input: createItemInput!) {
    createItem(input: $input) {
        item
    }
}
`;

export const UPDATE_ITEM = gql`
mutation updateItem($input: updateItemInput!) {
    updateItem(input: $input) {
        item
    }
}
`;

export const HIDE_ITEM = gql`
mutation hideItem($input: hideItemInput!) {
    hideItem(input: $input) {
        itemName {
            id
        }
    }
}
`;

export const DESTROY_ITEMS = gql`
mutation destroyItem($input: destroyItemInput!) {
    destroyItem(input: $input) {
        itemNames {
            id
        }
    }
}
`;

export const UPDATE_ITEM_NAME = gql`
mutation updateItemName($input: updateItemNameInput!) {
    updateItemName(input: $input) {
        itemName {
            id
            perBox
            workType
        }
    }
}
`;


export const CREATE_CAMPAIGN = gql`
mutation createCampaign($input: createCampaignInput!) {
    createCampaign(input: $input) {
        campaign {
            id
        }
    }
}
`;

export const UPDATE_CAMPAIGN = gql`
mutation updateCampaign($input: updateCampaignInput!) {
    updateCampaign(input: $input) {
        campaign {
            id
        }
    }
}
`;

export const DELETE_CAMPAIGNS = gql`
mutation deleteCampaign($input: deleteCampaignInput!) {
    deleteCampaign(input: $input) {
        campaigns {
            id
        }
    }
}
`;

export const CREATE_CAMPAIGN_PLAN = gql`
mutation createCampaignPlan($input: createCampaignPlanInput!) {
    createCampaignPlan(input: $input) {
        campaignPlan {
            campaignId
            startDt
            endDate
            itemIdList
        }
    }
}
`;

export const UPDATE_CAMPAIGN_PLAN = gql`
mutation updateCampaignPlan($input: updateCampaignPlanInput!) {
    updateCampaignPlan(input: $input) {
        campaignPlan {
            id
        }
    }
}
`;

export const DELETE_CAMPAIGN_PLANS = gql`
mutation deleteCampaignPlan($input: deleteCampaignPlanInput!) {
    deleteCampaignPlan(input: $input) {
        campaignPlans {
            id
        }
    }
}
`;

export const CREATE_CALENDAR_EVENT = gql`
mutation createCalendarEvent($input: createCalendarEventInput!) {
    createCalendarEvent(input: $input) {
        calendarEvent {
            name
            startDate
            endDate
        }
    }
}
`;
export const DELETE_CALENDAR_EVENTS = gql`
mutation deleteCalendarEvent($input: deleteCalendarEventInput!) {
    deleteCalendarEvent(input: $input) {
      calendarEvent {
        id
      }
    }
  }
`;

export const CREATE_FORWARDING_OFF_PLANS = gql`
mutation createForwardingOffPlan($input: createForwardingOffPlanInput!) {
    createForwardingOffPlan(input: $input) {
        forwardingOffPlan {
            offDays
        }
    }
}
`;

export const DELETE_FORWARDING_OFF_PLANS = gql`
mutation deleteForwardingOffPlan($input: deleteForwardingOffPlanInput!) {
    deleteForwardingOffPlan(input: $input) {
        forwardingOffPlan {
            offDays
        }
    }
}
`;

export const DELETE_USERS = gql`
mutation deleteUser($input: deleteUserInput!) {
    deleteUser(input: $input) {
        users {
            id
            email
        }
    }
}
`;

export const PASSWORD_RESET = gql`
mutation passwordReset($input: passwordResetUserInput!) {
    passwordResetUser(input: $input) {
        user {
            id
            email
            password
        }
    }
}`
;

export const GET_PRESIGNED_UPLOAD_URL = gql`
mutation presignedUploadUrl($objectKey: String!) {
    presignedUploadUrl(objectKey: $objectKey) {
        objectKey
        expiresAt
        uploadUrl
    }
}`;

export const CREATE_FAVORITE = gql`
mutation createFavorite($input: createFavoriteInput!) {
    createFavorite(input: $input) {
        favorite {
            id
            itemNameId
        }
    }
}`;

export const DELETE_FAVORITE = gql`
mutation deleteFavorite($input: deleteFavoriteInput!) {
    deleteFavorite(input: $input) {
        favorite {
            id
            itemNameId
        }
    }
}`;

export const CREATE_TAG = gql`
mutation createTag($input: createTagInput!) {
    createTag(input: $input) {
        tag {
            id
            name
        }
    }
}`;

export const DELETE_TAG = gql`
mutation deleteTag($input: deleteTagInput!) {
    deleteTag(input: $input) {
        tag {
            id
            name
        }
    }
}`;

export const CREATE_ITEM_TAG = gql`
mutation createItemTag($input: createItemTagInput!) {
    createItemTag(input: $input) {
        itemTag {
            id
            itemNameId
            tagId
        }
    }
}`;

export const DELETE_ITEM_TAG = gql`
mutation deleteItemTag($input: deleteItemTagInput!) {
    deleteItemTag(input: $input) {
        itemTag {
            id
            itemNameId
            tagId
        }
    }
}`;

export const MERGE_ITEM_NAMES = gql`
mutation mergeItemNames($input: mergeItemNamesInput!) {
    mergeItemNames(input: $input) {
        itemName {
            id   
        }
    }
}`;