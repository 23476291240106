import { toRomaji } from "wanakana";

const normalize = (str) => {
    return toRomaji(
        str
            .replaceAll("-", "")
            .toLowerCase()
            .replace(/[\uff01-\uff5e]/g, function (ch) {
                return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
            })
    );
};

export const isSearchMatch = (searchString, targetString) => {
    return normalize(targetString).includes(normalize(searchString));
};
