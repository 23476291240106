import React, { useState } from 'react';
import { Pagination, Image, Spin, Layout, Typography, Input, DatePicker, Tag, Col, Row, message } from 'antd';
import { GET_SELLING_ITEM_PLANS } from '../../../queries';
import { UPDATE_ITEM_NAME } from '../../../mutations';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import 'moment/locale/ja';
import SellingItemPlansExport from './SellingItemPlansExport';

const sortBySellingCycle = (a, b) => {
    if (a.isNew !== b.isNew) { // if not the same category
        return b.isNew - a.isNew;
    }
    if (a.isNew) {
        return moment(a.item_selling_cycle.selling_start_dt) - moment(b.item_selling_cycle.selling_start_dt);
    }
    return moment(a.item_selling_cycle.selling_end_dt) - moment(b.item_selling_cycle.selling_end_dt);
}

const SellingItemPlans = () => {
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [sellingItems, setSellingItems] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [selectedDate, setSelectedDate] = useState(
        localStorage.getItem('SellingItemCalendarDate') ? new Date(localStorage.getItem('SellingItemCalendarDate')) : new Date()
    );
    const { data, loading } = useQuery(GET_SELLING_ITEM_PLANS, { 
        variables: { month: moment(selectedDate).format('YYYY-MM-DD') }, 
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            setSellingItems(data.monthlySellingItems.map(e => {
                return { ...e, isNew: moment(e.item_selling_cycle?.selling_start_dt).month() === moment(selectedDate).month()}
            }))
        }
    });
    const [updateItemName] = useMutation(UPDATE_ITEM_NAME, {
        onCompleted: item => {
            message.success('商品の入数/業務を更新しました。');
            setSellingItems(sellingItems.map(e => {
                if (e.item_name?.id === parseInt(item?.updateItemName?.itemName?.id)) {
                    return { ...e, item_name: {...e.item_name, per_box: item?.updateItemName?.itemName?.perBox, work_type: item?.updateItemName?.itemName?.workType} }
                }
                return e;
            }))
        },
        onError: (error) => {
            console.error(error);
        }
    });

    const onMonthChange = (date) => {
        localStorage.setItem('SellingItemCalendarDate', date);
        setSelectedDate(date);
    }

    const handlePage = (page, pageSize) => {
        setCurrPage(page)
        setPageSize(pageSize)
    }

    return (
    <Layout style={{ padding: 24, background: '#fff' }}>
        <Row gutter={8} style={{ marginBottom: 24 }}>
            <Col align='left'>
                <DatePicker picker='month' onChange={onMonthChange} value={moment(selectedDate)} allowClear={false} />
            </Col>
            <Typography.Text style={{ margin: 'auto 0.5em', fontSize: '1rem'}}>販売商品</Typography.Text>
            <Col align='right' style={{ flex: 100 }}>
                {unsavedChanges && <Tag color='red'>未保存の変更があります</Tag>}
                <SellingItemPlansExport sellingItems={sellingItems} selectedDate={selectedDate} disabled={unsavedChanges}/>
            </Col>
        </Row>
        {loading ? 
            <Spin /> : 
            sellingItems && 
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                {sellingItems && sellingItems?.sort(sortBySellingCycle).map((item, index) => {
                    return index >= (currPage - 1) * pageSize && index < currPage * pageSize && (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column', width: '33%', alignItems: 'center', marginTop: '1rem'}}>
                        <div style={{ width: '80%', display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '30%' }}>
                                { item.isNew ? <Tag style={{ fontSize: '14px', color: 'black', borderRadius: '8px' }} color="#E8CE02">新発売</Tag> : <Tag style={{ fontSize: '14px', borderRadius: '8px' }} color="#3BA0E5">終売</Tag> }
                            </div>
                            <div style={{ width: '70%', textAlign: 'center' }}>
                                <Image width={100} height={100} crossOrigin="anonymous" src={item.download_url || ''} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width:'80%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography.Text style={{ width: '30%' }}>商品名</Typography.Text>
                                <Typography.Text style={{ width: '70%', textAlign: 'center'}}>{item.item_name?.item_name}</Typography.Text>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography.Text style={{ width: '30%' }}>本体売価</Typography.Text>
                                <Typography.Text data-testid='item-price-before-tax' style={{ width: '70%', textAlign: 'center' }}>{Math.round(item.item_selling_cycle?.regular_price / 1.08)}円</Typography.Text>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography.Text style={{ width: '30%' }}>税込み売価</Typography.Text>
                                <Typography.Text data-testid='item-price-after-tax' style={{ width: '70%', textAlign: 'center' }}>{item.item_selling_cycle?.regular_price}円</Typography.Text>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography.Text style={{ width: '30%'}}>発売日</Typography.Text>
                                <Typography.Text data-testid='selling-start' style={{ width: '70%', textAlign: 'center' }}>{moment(item.item_selling_cycle?.selling_start_dt).format('YYYY-MM-DD')}</Typography.Text>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography.Text style={{ width: '30%' }}>終売日</Typography.Text>
                                <Typography.Text data-testid='selling-end' style={{ width: '70%', textAlign: 'center' }}>{moment(item.item_selling_cycle?.selling_end_dt).format('YYYY-MM-DD')}</Typography.Text>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography.Text style={{ width: '50%' }}>入数</Typography.Text>
                                <Input
                                    type='number'
                                    style={{ height: '1.5rem', margin: '1px' }}
                                    key={`per-box-${item.item_name?.id}`}
                                    defaultValue={item.item_name?.per_box}
                                    onPressEnter={e => e.target.blur()}
                                    min={0}
                                    onKeyDown={e => {
                                        if (e.keyCode === 109 || e.keyCode === 189 || e.keyCode === 69) { // for minus || minus(JP) || 'e'
                                            e.preventDefault();
                                        } else if (item.item_name?.per_box && e.keyCode === 8) { // for backspace
                                            setUnsavedChanges(true)
                                        }
                                    }}
                                    onBlur={e => {
                                        if (!e.target.value && !item.item_name?.per_box) { return }
                                        if (e.target.value !== String(item.item_name?.per_box)) {
                                            updateItemName({
                                                variables: { input: {
                                                    id: item.item_name?.id,
                                                    perBox: parseInt(e.target.value) || null,
                                                    workType: item.item_name?.work_type,
                                                } }
                                            })
                                            setUnsavedChanges(false)
                                        }
                                    }}
                                    data-testid='per-box'
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography.Text style={{ width: '50%'}}>業務</Typography.Text>
                                <Input
                                    key={`work-type-${item.item_name?.id}`}
                                    style={{ height: '1.5rem', margin: '1px' }}
                                    defaultValue={item.item_name?.work_type}
                                    onPressEnter={e => e.target.blur()}
                                    onKeyDown={e => {
                                        if (item.item_name?.work_type && e.keyCode === 8) { // for backspace
                                            setUnsavedChanges(true)
                                        }
                                    }}
                                    onKeyPress={() => setUnsavedChanges(true)}
                                    onBlur={e => {
                                        if (!e.target.value && !item.item_name?.work_type) { return }
                                        if (e.target.value !== String(item.item_name?.work_type)) {
                                            updateItemName({
                                                variables: { input: {
                                                    id: item.item_name?.id,
                                                    perBox: item.item_name?.per_box,
                                                    workType: e.target.value,
                                                } }
                                            })
                                            setUnsavedChanges(false)
                                        }
                                    }}
                                    data-testid='work-type'
                                />
                            </div>
                        </div>
                    </div>
                )})}
            </div>
        }
        <Pagination 
            style={{ textAlign: 'end', marginTop: '2rem' }} 
            defaultCurrent={1} 
            onChange={handlePage} 
            pageSize={pageSize}
            current={currPage}
            showSizeChanger={true}
            pageSizeOptions={[9, 18, 36, 72]}
            total={data?.monthlySellingItems?.length} />
    </Layout>
    )
}

export default SellingItemPlans;