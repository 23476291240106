import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Popconfirm, message } from 'antd';
import { DELETE_CAMPAIGNS } from '../../mutations';

const DeleteCampaignsButton = ({ selectedCampaignIds, handleCompleted }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [deleteCampaigns] = useMutation(DELETE_CAMPAIGNS, {
        onCompleted: () => handleCompleted(),
        onError: (error) => {
            console.log(error);
        }
    });

    const showPopconfirm = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        deleteCampaigns({ variables: { input: { ids: selectedCampaignIds } } })
        .then(res => {
            setConfirmLoading(false);
            setVisible(false);
            if (res.errors) {
                message.error('選択したキャンペーンを削除できませんでした。実施計画がカレンダーに追加されているキャンペーンは削除できません。');
            } else {
                message.success('選択したキャンペーンを削除しました。');
            }
        })
        .catch(() => { // this doesn't get called for some reason when the backend responds with 500
            setConfirmLoading(false);
            setVisible(false);
            message.error('選択したキャンペーンを削除できませんでした。実施計画がカレンダーに追加されているキャンペーンは削除できません。');
        });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Popconfirm
            title='選択したキャンペーンを削除してよろしいですか。'
            cancelText='キャンセル'
            okText='削除する'
            open={visible}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
            placement='topRight'
        >
            <Button
                type='link'
                onClick={showPopconfirm}>
                削除
            </Button>
        </Popconfirm>
    );
};

export default DeleteCampaignsButton;
