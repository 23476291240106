import React, { useState, useEffect, useMemo } from 'react';
import {
    Spin,
    Layout,
    Space,
    Button,
    Typography,
    DatePicker,
    Form,
    Select,
    Checkbox,
    Tooltip,
    message
} from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { GET_CAMPAIGNS, GET_ALL_ITEMS } from '../../queries';
import { CREATE_CAMPAIGN_PLAN } from '../../mutations';
import { useQuery, useMutation } from '@apollo/client';
import { isSearchMatch } from '../../Utilities/isSearchMatch';
import { localTimeZoneToUTC } from '../../Utilities/date';

const AddNewCampaignPlan = ({ setAddingPlan, refetch }) => {

    const { data: rawData, loading } = useQuery(GET_ALL_ITEMS, { errorPolicy: 'all', notifyOnNetworkStatusChange: true });

    const data = useMemo(() => {
        if (rawData) {
            return JSON.parse(rawData.allItems)
        }
    }, [rawData])

    const { data: campaignData, loading: campaignLoading } = useQuery(GET_CAMPAIGNS);
    const [campaigns, setCampaigns] = useState([])
    const [createCampaignPlan] = useMutation(CREATE_CAMPAIGN_PLAN, {
        onCompleted: (data) => {
            message.success('キャンペーン計画を追加しました。');
            form.resetFields();
            refetch();
            setAddingPlan(false);
        },
        onError: (error) => {
            console.log(error);
            if (error.message.includes("itemIdList")) {
                message.error("商品名を選択するか、全ての商品をチェックしてください")
            } else {
                message.error('キャンペーン計画追加に失敗しました。');
            }
        }
    });
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([])
    const [all, setAll] = useState(false)
    const [form] = Form.useForm();

    const onSubmit = (values) => {
        const variables = {...values}
        if (all) {
            variables.items = filteredProducts.map(e => {return e.id})
        }
        createCampaignPlan({ variables: { input: {
            campaignId: variables.campaign,
            startDt: localTimeZoneToUTC(variables.campaignRange[0]),
            endDate: localTimeZoneToUTC(variables.campaignRange[1]),
            itemIdList: variables.items
        }}});
    }

    const filterProducts = (range) => {
        setFilteredProducts(data) // reset
        if(!range) {
            return
        }
        const start = range[0].format().slice(0,10)
        const end = range[1].format().slice(0,10)
        console.log(start, end)
        const filtered = products.filter(e => {
            const cycles = e.item_selling_cycles
            const status = e.user_defined_status ? e.user_defined_status : e.status
            if (status === 'Regular') {
                console.log('regular')
                return true
            }
            if (cycles.length > 0) {
                let sold = false
                cycles.forEach(cycle => {
                    if (cycle.selling_start_dt.slice(0,10) <= end && cycle.selling_end_dt.slice(0,10) >= start) {
                        sold = true
                    }
                })
                return sold
            }
            return false
        })
        setFilteredProducts(filtered)
    }

    const onSelect = e => {
        setAll(e.target.checked)
    }

    const campaignSelectionName = (campaign) => {
        return [
            campaign.campaignName, 
            campaign.presentName, 
            campaign.campaignDiscountPrice, 
            campaign.campaignDiscountRate
        ].filter(x => x).join('-')
    }

    useEffect(() => {
        if (data) {
            setProducts(data);
            setFilteredProducts(data)
        }
    }, [data]);

    useEffect(() => {
        if(campaignData) {
            setCampaigns(campaignData?.campaigns)
        }
    }, [campaignData])

    const formCampaignLayout = {
        labelCol: {  
            xs: { span: 24 },
            sm: { span: 24 },
            md:  { span: 24 },
            lg:  { span: 6 } 
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md:  { span: 24 },
            lg:  { span: 8 }
        },
        layout: 'horizontal',
        labelAlign: 'right'
    };

    const tooltipTitle = "全商品を選択した場合、上記のキャンペーン期間中に販売期間が設定されている商品が全てこのキャンペーン計画に紐づけられます。個々の商品名を選択する必要はありません。"

    if (loading || campaignLoading) {
        return <Spin/>;
    }

    return <>
        <Layout style={{ background: '#fff' }}>
            <Typography.Title level={4}>キャンペーン計画追加</Typography.Title>
        </Layout>
        <Layout style={{ padding: 24, background: '#fff' }}>
            <Form
                form={form}
                {...formCampaignLayout}
                onFinish={onSubmit}
            >
                <Form.Item label='キャンペーン名' name='campaign' required
                        rules={[{ required: true, message: 'キャンペーン名が必要です' }]}>
                    <Select 
                        placeholder="キャンペーン名"
                        showSearch
                        filterOption={(input, option) => {
                            const searchString = input.toLowerCase();
                            const targetString = option.children.toLowerCase();
                            return isSearchMatch(searchString, targetString);
                        }}
                    >
                        {campaigns.map(campaign => (
                            <Select.Option data-testid='campaign-options' key={campaign.id}>{campaignSelectionName(campaign)}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label='キャンペーン期間' name='campaignRange' required
                        rules={[{ required: true, message: '期間が必要です' }]}>
                    <DatePicker.RangePicker onChange={filterProducts} />
                </Form.Item>
                <Form.Item label='商品名' name='items'
                    rules={[{ required: !all, message: '商品名が必要です。' } ]} required>
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder={all ? "OK-全商品選択済み" : "商品を入力してください"}
                        disabled={all}
                        filterOption={(input, option) => {
                            const searchString = input.toLowerCase();
                            const targetString = option.children[0].toLowerCase();
                            return isSearchMatch(searchString, targetString) || option.children.at(-1).includes(searchString)
                        }}
                    >
                        {filteredProducts.map(product => {
                            return <Select.Option data-testid='item-names' key={product.id}>{product.item_name}{product.item_size ? " - " + product.item_size : null}{product.item_mappings?.map(im => " " + im.prod_id)?.toString()}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
                    
                <Form.Item 
                    colon={false}
                    label=' '
                    name='allProductsSelected'
                    valuePropName="checked">
                    <Checkbox style={{ marginRight: '.5rem'}} onChange={onSelect}/>
                    全ての商品を選択する
                    <Tooltip placement='bottomLeft' title={tooltipTitle}>
                        <QuestionCircleTwoTone /> 
                    </Tooltip>
                </Form.Item>
                <Form.Item colon={false} label=' '>
                    <Space>
                        <Button type='primary' htmlType='submit'>追加する</Button>
                        <Button onClick={() => { refetch(); setAddingPlan(false); }}>キャンセル</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Layout>
    </>
};

export default AddNewCampaignPlan;
