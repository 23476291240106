import React, { useState } from 'react';
import { Dropdown, Layout, Menu, Typography } from 'antd';
import propTypes from 'prop-types';
import './authorizedlayout.less';
import { NavLink, withRouter } from 'react-router-dom';
import { PATHS }  from '../../constants';
import {
    LineChartOutlined,
    EditOutlined,
    SettingOutlined,
    LogoutOutlined,
    DownOutlined
} from '@ant-design/icons';
import { AUTH_CHECK } from '../../queries';
import { useQuery } from '@apollo/client';
import history from '../../Utilities/history';
import { authenticationService } from '../../Utilities/authenticationService';

const { Header, Content, Sider } = Layout;

const AuthorizedLayout = ({ location, children, disableBorder, title }) => {
    const [collapsed, setCollapsed] = useState(false);
    useQuery(AUTH_CHECK, {
       onError: () => {
            authenticationService.logout();
            history.push({
                pathname: PATHS.login,
                state: { from: { location: location.pathname } }
            });
        }
    });

    const handleLogout = () => {
        authenticationService.logout();
        history.push('/login');
    }

    const UserMenu = <Menu key="user-panel" style={{ background: '#fff' }}>
        <Menu.Item icon={<SettingOutlined />} key="settings"><NavLink to={PATHS.userSettings}>アカウント</NavLink></Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} key='logout' onClick={handleLogout}>ログアウト</Menu.Item>
    </Menu>;

    const Sidebar = <Sider className="site-layout-background site-layout-sidebar" collapsible onCollapse={value => setCollapsed(value)}>
        <img src={require('../../static/ApplepieLogo.svg')} alt='applepielogo' className='app-logo' style={{padding: collapsed && 10}} />
        <Menu
            mode="inline"
            defaultOpenKeys={['data']}
            style={{ height: '100%', borderRight: 0 }}
        >
            <Menu.Item key="estimations" icon={<LineChartOutlined />}>
                <NavLink to={'/estimations'}>商品予測</NavLink>
            </Menu.Item>
            <Menu.Item key="addMenu" icon={<EditOutlined />}>
                <NavLink to={PATHS.addMenu}>商品管理</NavLink>
            </Menu.Item>
        </Menu>
    </Sider>;

    const Topbar = <Header className="main-header" style={{ background: '#fff' }}>
        <Typography.Title level={4} style={{float: "left", marginTop: "0.8em", marginLeft: "0.8em"}}>{title}</Typography.Title>
        <Dropdown className='user-panel' overlay={UserMenu} trigger={['click']}>
            <div>
                { JSON.parse(localStorage.currentUser).last_name } { JSON.parse(localStorage.currentUser).first_name }
                <DownOutlined />
            </div>
        </Dropdown>
    </Header>;

    return <Layout>
        <Layout>
            { Sidebar }
            <Layout style={{marginLeft: collapsed ? 80 : 200}}>
                { Topbar }
                <Content
                    className={ disableBorder ? '' : 'site-layout-background' }
                    style={{
                        margin: 0,
                        minHeight: 280,
                        marginTop: 64,
                        background: '#fff'
                    }}
                >
                    { children }
                </Content>
            </Layout>
        </Layout>
    </Layout>;
};

AuthorizedLayout.propTypes = {
    children: propTypes.element,
    onLogout: propTypes.func,
    location: propTypes.object,
    disableBorder: propTypes.bool
};

export default withRouter(AuthorizedLayout);
