import React, { useState, useEffect, useMemo } from 'react';
import {
    Layout,
    Button,
    Input,
    Typography,
    Row,
    Col,
    Spin,
    Table,
    Space,
    Tag,
    Popover,
    Image
} from 'antd';
import { PlusCircleOutlined, CheckCircleOutlined, StarOutlined, StarTwoTone} from '@ant-design/icons';
import EditItem from '../AddMenu/EditItem';
import AddNewItem from '../AddMenu/AddNewItem';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { GET_ALL_ITEMS, GET_FAVORITES, GET_USER_TAGS, GET_ITEM_TAGS } from '../../queries';
import { HIDE_ITEM, DESTROY_ITEMS, CREATE_FAVORITE, DELETE_FAVORITE, CREATE_ITEM_TAG, DELETE_ITEM_TAG } from '../../mutations';
import moment from 'moment';
import { withRouter, useHistory } from 'react-router-dom';
import { filterRowsByQueryAndFilterParams } from '../../Utilities/listFilter';

import { Tags } from '../Estimations/Estimations';

const ItemList = ({ shouldRefetch }) => {
    const history = useHistory();
    const [addingItem, setAddingItem] = useState(false);
    const [editingItem, setEditingItem] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [itemNameId, setItemNameId] = useState(null);
    const { data: rawData, loading, refetch, networkStatus } = useQuery(GET_ALL_ITEMS, { errorPolicy: 'all', notifyOnNetworkStatusChange: true });
    
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [imageSrc, setImageSrc] = useState();
    const [zoom, setZoom] = useState();
    const [currentPage, setCurrentPage] = useState(parseInt(localStorage.getItem('prodManagementPage')) || 1);
    const [pageSize, setPageSize] = useState(parseInt(localStorage.getItem('prodManagementPageSize')) || 5);
    const [filterFavorites, setFilterFavorites] = useState(false);

    const [selectedTags, setSelectedTags] = useState([]);

    const data = useMemo(() => {
        if (rawData) {
            return JSON.parse(rawData.allItems)
        }
    }, [rawData])

    const currentUser = useMemo(() => {
        return JSON.parse(localStorage.getItem('currentUser'))
    }, [])

    const { data: favorites, refetch: refetchFavorites } = useQuery(GET_FAVORITES, { variables: { userId: currentUser.id } });

    const { data: tags, refetch: refetchTags } = useQuery(GET_USER_TAGS, { variables: { userId: currentUser.id }, fetchPolicy: 'no-cache' });

    const {data: itemTags, refetch: refetchItemTags} = useQuery(GET_ITEM_TAGS, {variables: {userId: currentUser.id}, fetchPolicy: 'no-cache'});

    useEffect(() => {
        if (shouldRefetch === "tags") {
            refetchTags();
            refetchItemTags()
        }
    }, [shouldRefetch, refetchTags, refetchItemTags])

    const formattedTags = useMemo(() => {
        if (tags) {
            return tags.tags.map(tag => {
                return {
                    label: tag.name,
                    value: tag.color,
                    id: tag.id
                }
            })
        }
        return []
    }, [tags])

    const [createFavorite] = useMutation(CREATE_FAVORITE, {
        onCompleted: () => {
            refetchFavorites();
        },
        onError: (error) => {
            console.log(error);
        }
    })

    const [deleteFavorite] = useMutation(DELETE_FAVORITE, {
        onCompleted: () => {
            refetchFavorites();
        },
        onError: (error) => {
            console.log(error);
        }
    })

    const [tagItem] = useMutation(CREATE_ITEM_TAG, {
        onCompleted: () => {
            refetchItemTags();
        },
        onError: (error) => {
            console.log(error);
        }
    })

    const [untagItem] = useMutation(DELETE_ITEM_TAG, {
        onCompleted: () => {
            refetchItemTags();
        },
        onError: (error) => {
            console.log(error);
        }
    })

    const [hideItem] = useMutation(HIDE_ITEM, {
        onCompleted: (data) => {
            refetch();
            localStorage.setItem('itemsRefetchFlag', 1);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const [deleteItem] = useMutation(DESTROY_ITEMS, {
        onCompleted: (data) => {
            refetch();
            localStorage.setItem('itemsRefetchFlag', 1);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    useEffect(() => {
        if (data) {
            const parsedRows = data.map(
                itemName => {
                    const latestCycle = itemName.item_selling_cycles && itemName.item_selling_cycles.length > 0 ? 
                        itemName.item_selling_cycles.reduce((prev, curr) => curr.selling_end_dt > prev?.selling_end_dt ? curr : prev) : null
                    const profile = itemName.item_profile || null
                    const status = itemName.user_defined_status ? itemName.user_defined_status : itemName.status;
                    const isFavorite = favorites?.favorites?.map(favorite => parseInt(favorite.itemNameId)).includes(parseInt(itemName.id));
                    const tags = itemTags?.itemTags.filter(itemTag => parseInt(itemTag.itemName.id) === itemName.id)
                    const formattedTags = tags?.map(t => ({id: t.id, label: t.tag.name, value: t.tag.color, tagId: t.tag.id}))
                    return {
                        key: itemName.id,
                        id: itemName.id,
                        name: itemName.item_name,
                        nameInfo: { itemNameId: itemName.id, name: itemName.item_name },
                        description: profile?.item_detail,
                        image: { url: profile?.download_url || "no url", name: profile?.item_img_file || "image.png"},
                        products: itemName.item_mappings,
                        hidden: itemName.hidden,
                        status,
                        size: itemName.item_size || '',
                        updated_at: itemName.updated_at,
                        updated_by: itemName.updated_by_user?.email || '',
                        selling_period: itemName.item_selling_cycles && itemName.item_selling_cycles.length > 0 ? {
                            period: `${latestCycle.selling_start_dt.split('T')[0]} - ${latestCycle.selling_end_dt.split('T')[0]}`,
                            start: latestCycle.selling_start_dt.split('T')[0],
                            end: latestCycle.selling_end_dt.split('T')[0],
                        } : null,
                        latestCycle: latestCycle,
                        latest_price: latestCycle?.regular_price,
                        favorite: isFavorite,
                        tags: formattedTags,
                        productCodes: itemName.item_mappings?.map(e => e.prod_id),
                    }
                });
                setRows(parsedRows);
                setOriginalRows(parsedRows);
        }
    }, [data, favorites, itemTags]);

    useEffect(() => {
        setRows(filterRowsByQueryAndFilterParams(originalRows, searchText, filterFavorites, null, selectedTags))
    }, [originalRows, searchText, filterFavorites, setRows, selectedTags]);

    useEffect(() => {
        if (history?.location?.state?.searchText) {
            setSearchText(history.location.state.searchText)
            delete history.location.state.searchText
            history.replace(history.location)
        }
    }, [history])
    const columns = [
        {
            title: <Typography.Text strong></Typography.Text>,
            dataIndex: 'favorite',
            width: 50,
            render: (favorite, row) => {
                return !favorite ? <StarOutlined style={{fontSize: "20px", color: "lightgrey"}} onClick={e => createFavorite({ variables: { input: { itemNameId: row.key}}})} />
                : <StarTwoTone style={{fontSize: "20px"}} onClick={e => deleteFavorite({ variables: { input: { itemNameId: row.key }}})} />
            }
        },
        {
            title: <Typography.Text strong>タグ</Typography.Text>,
            dataIndex: 'tags',
            width: 100,
            render: (tags, row) => {
                return <Tags currentTags={tags} tagList={formattedTags} tagItem={tagItem} untagItem={untagItem} itemNameId={row.key}/>
            }
        },
        {
            title: <Typography.Text strong>商品名</Typography.Text>,
            dataIndex: 'nameInfo',
            width: 180,
            render: x => <a style={{ color: 'black' }} onClick={() => { // eslint-disable-line
                if (currentUser?.department === 'sweets') {
                    history.push('/itemDetails/' + x.itemNameId)
                }
                
                history.push('/estimations/' + x.itemNameId)
            }}>{x.name}</a>,
        },

        {
            title: <Typography.Text strong>商品説明</Typography.Text>,
            dataIndex: 'description',
            width: 250,
            render: desc => {
                return desc?.length > 100 ? desc?.substr(0,97) + "..." : desc?.substr(0,100)
            }            
        },
        {
            title: <Typography.Text strong>商品ID</Typography.Text>,
            dataIndex: 'key',
            sorter: (a, b) => parseInt(a.key) - parseInt(b.key),
            width: 75
        },
        {
            title: <Typography.Text strong>製品</Typography.Text>,
            dataIndex: 'products',
            sorter: (a, b) => a.products ? true : false,
            render: products => {
                return products ? products.map((e, i) => (
                    <div key={e.prod_id + e.prod_name}>
                        {e.prod_id} - {e.prod_name}
                    </div>
                )) : null
            },
            width: 200
        },
        {
            title: <Typography.Text strong>販売期間</Typography.Text>,
            dataIndex: 'selling_period',
            sorter: (a, b, sortOrder) => {
                if (a.selling_period?.end && b.selling_period?.end) {
                    if (a.selling_period.end === b.selling_period.end) {
                        if (a.selling_period.start === b.selling_period.start) {
                            return 0
                        } else {
                            return a.selling_period.start > b.selling_period.start ? 1 : -1
                        }
                    } else {
                        return a.selling_period.end > b.selling_period.end ? 1 : -1
                    }
                } else if (sortOrder === 'ascend' && a.selling_period?.end) {
                    return -1;
                } else if (sortOrder === 'ascend' && b.selling_period?.end) {
                    return 1
                } else if (sortOrder === 'descend' && a.selling_period?.end) {
                    return 1;
                } else if (sortOrder === 'descend' && b.selling_period?.end) {
                    return -1
                }
                 else {
                    return 0;
                }                
            },
            render: x => x?.period,
            width: 150,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: <Typography.Text strong>価格（税込）</Typography.Text>,
            dataIndex: 'latest_price',
            sorter: (a, b) => {
                if (a.latest_price && b.latest_price) {
                    return a.latest_price - b.latest_price;
                } else if (a.latest_price) {
                    return 1;
                } else if (b.latest_price) {
                    return -1
                } else {
                    return 0;
                }
            },
            render: x => (x ? `¥${parseInt(x).toLocaleString('ja-JP')}` : null),
            width: 125
        },
        {
            title: <Typography.Text strong>サイズ</Typography.Text>,
            dataIndex: 'size',
            sorter: (a, b) => a.size.localeCompare(b.size),
            render: x => (x || 'バラ'),
            width: 100
        },
        {
            title: <Typography.Text strong>商品画像</Typography.Text>,
            dataIndex: 'image',
            sorter: (a, b) => a.size.localeCompare(b.size),
            render: x => (x.url !== 'no url' ? <a onClick={e => { // eslint-disable-line
                setImageSrc(x.url)
                setZoom(true)
            }}>{x.name}</a> : null),
            width: 100
        },
        {
            title: <Typography.Text strong>定番商品</Typography.Text>,
            dataIndex: 'status',
            sorter: (a, b) => ((a.status === b.status) ? 0 : a.status === "Regular" ? -1 : 1),
            render: x => (x === "Regular" ? <CheckCircleOutlined style={{fontSize: "1rem", marginLeft: "40%"}}/> : null),
            width: 100
        },
        {
            title: <Typography.Text strong>ステータス</Typography.Text>,
            dataIndex: 'hidden',
            sorter: (a, b) => ((a.hidden === b.hidden) ? 0 : a.hidden ? -1 : 1),
            render: x => (<Tag color={x ? 'error' : 'success'}>{x ? '非表示' : '表示中'}</Tag>),
            width: 100
        },
        {
            title: <Typography.Text strong>編集者</Typography.Text>,
            dataIndex: 'updated_by',
            sorter: (a, b) => a.updated_by.localeCompare(b.updated_by),
            width: 180
        },
        {
            title: <Typography.Text strong>追加・変更日時</Typography.Text>,
            dataIndex: 'updated_at',
            align: 'right',
            sorter: (a, b) => {
                if (a.updated_at && b.updated_at) {
                    return a.updated_at < b.updated_at ? 1 : -1
                }
                return -1
            },
            render: x => 
                x ? <div>
                        <div>{moment(x).format('YYYY-MM-DD')}</div>
                        <div>{moment(x).format('a h:mm')}</div>
                    </div> : null,
            width: 125
        },
        {
            title: '',
            fixed: 'right',
            render: (text, rowContent) => {
                return <div style={{display: 'flex', justifyContent: "space-evenly"}}>
                <Button type='link' style={{ paddingRight: '.5rem', paddingLeft: '.5rem' }} onClick={() => {
                    setEditingItem(true);
                    setItemNameId(rowContent.key);
                }}>編集</Button>
                { loading ? 
                    <Spin /> :
                    <Button type='link' style={{ paddingRight: '.5rem', paddingLeft: '.5rem' }} onClick={() => {
                        hideItem({ variables: { input: { id: rowContent.key } } });
                    }}>
                        { rowContent.hidden ? '表示' : '非表示' }
                    </Button>
                }
                {currentUser?.admin && <Button type='link' onClick={() => {
                    setRows(rows.filter(e => e.key !== rowContent.key))
                    deleteItem({ variables: { input: { ids: [rowContent.key] } } })
                }}>削除</Button>}
            </div>
            },  
            width: 125
        }
    ];

    const handleTagChange = (tag, checked) => {
		const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
		setSelectedTags(nextSelectedTags);
	};
    
    const helpContent = <>
        <b>＜サイズで検索する場合＞</b>
        <p>半角英字でスペースを空けずに「size:S/M/L」と入力してください。
            <br></br>入力例：size:S、size:M、size:L、size:バラ</p>　
</>;


    const handleSearch = e => {
        setSearchText(e.target.value);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem('prodManagementPage', page);
    };

    const handlePageSizeChange = (page, size) => {
        setPageSize(size);
        localStorage.setItem('prodManagementPageSize', size);
    };

    const EstimationsTable = <Layout style={{ padding: 24, background: '#fff' }}>
        <Row gutter={[16,8]}>
            <Col>
                <Input.Search autoFocus style={{ width: '600px' }} value={searchText} onChange={handleSearch} allowClear placeholder="検索" />
                <Button type='link'>
                    <Popover title='商品の検索方法' trigger='click' content={helpContent}>
                            商品の検索方法
                    </Popover>
                </Button>
            </Col>
            <Col style={{flex: 1}} align='left'>
                <Space direction='horizontal'>
                    <Button 
                        onClick={() => {
                           setFilterFavorites((prev => !prev))
                        }} 
                        type='primary' 
                        >
                        {filterFavorites ? '全て表示' : 'お気に入りのみ表示'}
                    </Button>
                </Space>
            </Col>
            <div style={{flex: 3, display: "flex", alignItems: "center"}} align='left'>
                <span style={{ marginRight: 8 }}>タグ:</span>
                <Space size={[0, 8]} wrap>
                    {formattedTags.map((tag) => (
                        <Tag.CheckableTag
                            style={{ backgroundColor: selectedTags.includes(tag.id) ? tag.value : 'white', border: !selectedTags.includes(tag.id) && "1px solid "+tag.value, color: selectedTags.includes(tag.id) ? 'white' : tag.value}}
                            key={tag.id}
                            checked={selectedTags.includes(tag.id)}
                            onChange={(checked) => handleTagChange(tag.id, checked)}
                        >
                            {tag.label}
                        </Tag.CheckableTag>
                    ))}
                </Space>
            </div>
            {currentUser?.department === 'sweets' && <Col style={{flex: 1}} align='right'>
                <Space direction='horizontal'>
                    <Button 
                        onClick={() => {
                            history.push("/addMenu");
                            setAddingItem(true);
                        }} 
                        type='primary' 
                        icon={<PlusCircleOutlined />}>
                        商品追加
                    </Button>
                </Space>
            </Col>}
        </Row>
        <br/>
        { loading && networkStatus !== NetworkStatus.refetch ?
            <Spin /> :
            <Table
                columns={columns}
                dataSource={rows}
                scroll={{ x: 2500 }}
                pagination={{
                    pageSize: pageSize,
                    current: currentPage,
                    onChange: handlePageChange,
                    onShowSizeChange: handlePageSizeChange,
                    pageSizeOptions: [5, 10, 20, 50, 100]
                }}
            />
        }
    </Layout>;

    const AddNewItemWithHistory = withRouter(AddNewItem);

    return (
        <>
            { zoom && <div style={{ display: "none" }}>
                <Image
                    width={200}
                    placeholder={<Spin />}
                    preview={{
                        visible: zoom,
                        src: imageSrc,
                        onVisibleChange: (value) => {
                            setImageSrc('')
                            setZoom(value);
                        },
                    }}
                />
            </div>}
            {addingItem ? (
                <AddNewItemWithHistory setAddingItem={setAddingItem} refetch={refetch}
                    handleCancel={() => {
                        setAddingItem(false);
                    }}
                />
            ) : editingItem ? (
                <EditItem
                    itemNameId={itemNameId}
                    handleFinishEdit={() => {
                        setEditingItem(false);
                        refetch();
                    }}
                    itemNames={originalRows}
                />
            ) : (
                EstimationsTable
            )}
        </>
    );
};

export default ItemList;
