import React from 'react';
import propTypes from 'prop-types';
import { AuthorizedLayout } from '../';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../../Utilities/authenticationService';
import { PATHS } from '../../constants';

const PrivateRoute = ({ component: Component, disableBorder, onLogout, title, componentProps, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: PATHS.login, state: { from: props.location } }} />;
        }

        // authorized so return component
        return <AuthorizedLayout onLogout={onLogout} disableBorder={disableBorder} title={title}>
            <Component {...props} {...componentProps} />
        </AuthorizedLayout>;
    }} />
);

PrivateRoute.propTypes = {
    location: propTypes.object,
    component: propTypes.func,
    disableBorder: propTypes.bool,
    onLogout: propTypes.func
};

export default PrivateRoute;
